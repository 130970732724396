import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ArticlesBox from '../../Components/Leyouts/Articles/ArticlesBox';
import { useLocation, Link } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useInfiniteQuery,useQuery } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import empty from './../../Assets/Images/Account/order-empty.svg';

const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};

const fetchData = async (page = 1, categoryid, query) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let categoryQuery = '';
    if (categoryid && categoryid !== "all") {
        categoryQuery = `&categories[0]=${categoryid}`;
    }

    let searchQuery = '';
    if (query) {
        searchQuery = `&search=${query}`;
    }

    const response = await fetch(`https://backend.neireez.com/v1/posts?per_page=10&page=${page}${categoryQuery}${searchQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchCategory = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/categories?type=post`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Articles = () => {
    const [categoryid, setCategoryid] = useState('all');
    const [bottomReached, setBottomReached] = useState(false);
    const queryParams = useQueryParams();
    const query = queryParams.get('q');
    const categoryQ = queryParams.get('category');

    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);
    const { data: lastPosts, fetchNextPage, refetch, hasNextPage, isLoading, isError, error } = useInfiniteQuery(
        ['page', categoryid, query],
        ({ pageParam = 1 }) => fetchData(pageParam, categoryid, query),
        {
            getNextPageParam: (lastPage) => 
                lastPage.meta.current_page < lastPage.meta.last_page 
                    ? lastPage.meta.current_page + 1 
                    : null,
            refetchOnWindowFocus: false,
        }
    );

    const { data : category, isLoadingCategory, errorSlider } = useQuery(['data', 'category'], () => fetchCategory(),{
            retry: false,
            refetch: false,
            refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (categoryQ && category) {
            setCategoryid(categoryQ);
        }
    }, [categoryQ,category])

    useEffect(() => {
        const handleScroll = () => {
            const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 300;
            setBottomReached(isBottom);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (bottomReached && hasNextPage && !isLoading) {
            fetchNextPage();
        }
    }, [bottomReached, fetchNextPage, hasNextPage, isLoading]);

    useEffect(() => {
        if (categoryid) {
            refetch();
        }
    }, [categoryid, refetch]);

    const changeCategory= (e) => {
        setCategoryid(e);
    }
    
    return (
        <>
            <Helmet>
                <title>مقالات - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="مقالات - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main id='blog-main'>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem] pb-[1rem]'>
                    <h4 className='text-[28px] font-[700]'>{(query) ? `نتایج جستجو برای “${query}”` : 'مقالات'}</h4>
                </section>

                <section>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        className="slider-tags-blogs pb-[2rem]"
                        slidesPerView={'auto'}
                        grabCursor={true}
                        spaceBetween={16}
                        slidesOffsetAfter={32}
                        slidesOffsetBefore={32}
                        breakpoints={{
                            1024: {
                            slidesOffsetAfter :64,
                            slidesOffsetBefore : 64,
                            },
                        }}
                        speed={500}
                    >
                        {isLoadingCategory ? (
                                <>
                                    <SwiperSlide className="!w-max">
                                        <div className='relative w-[100px] h-[40px] rounded-[12px] bg-[#e0e0e0] overflow-hidden'>
                                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                        </div>
                                    </SwiperSlide>
                                    
                                    <SwiperSlide className="!w-max">
                                        <div className='relative w-[100px] h-[40px] rounded-[12px] bg-[#e0e0e0] overflow-hidden'>
                                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                        </div>
                                    </SwiperSlide>
                                </>
                        ) : (
                            <>
                                <SwiperSlide className="!w-max">
                                    <button onClick={(e) => changeCategory('all')} className={`w-full rounded-[12px] px-[8px] py-[4px] border-[2px] ${('all' == categoryid) ? 'bg-[#EEF8F6] border-[#089584]' : 'border-[#F0F0F0]'}`}>
                                        <p>همه</p>
                                    </button>
                                </SwiperSlide>
                                
                                {category?.data && Array.isArray(category?.data) ? (
                                    category?.data.map((data,index) => (
                                        <SwiperSlide className="!w-max" key={Math.random()}>
                                            <button onClick={(e) => changeCategory(data?.id)} className={`w-full rounded-[12px] px-[8px] py-[4px] border-[2px] ${(data?.id == categoryid) ? 'bg-[#EEF8F6] border-[#089584]' : 'border-[#F0F0F0]'}`}>
                                                <p>{ data?.title }</p>
                                            </button>
                                        </SwiperSlide>
                                    ))
                                ) : (
                                    <p></p>
                                )}
                            </>
                        )}
                    </Swiper>
                </section>

                <section className='mb-[2rem] px-[2rem] lg:px-[4rem]'>
                    {isLoading ? (
                        <>
                            <section className='flex justify-center items-center flex-col lg:flex-row my-[2rem]'>
                                <div className='w-full relative lg:w-[50%] lg:ml-[1rem] h-[200px] lg:h-[400px] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden'>
                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                </div>

                                <div className='w-full lg:w-[50%] lg:mr-[1rem] mt-[1rem] h-full'>
                                    <div className='mb-[1rem] relative h-[38px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem]'>
                                        <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                        <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                    </div>

                                    <div className='flex justify-start items-center mb-[1rem]'>
                                        <div className='w-[3.5rem] h-[3.5rem] min-w-[3.5rem] lg:w-[4rem] lg:h-[4rem] lg:min-w-[4rem] bg-[#e0e0e0] overflow-hidden rounded-full relative'>
                                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                        </div>

                                        <div className='w-full flex-row lg:flex-col flex justify-between lg:justify-center items-start mr-[12px]'>
                                            <p className='text-[#3C3C3C] w-[50%] h-[3.5rem] min-w-[3.5rem] lg:h-[4rem] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem] relative'>
                                                <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                                <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                            </p>
                                        </div>
                                    </div>

                                    <div className='lg:h-[190px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem] relative'>
                                        <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                        <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                    </div>

                                    <div className='mt-[1rem]'>
                                        <div className='flex justify-center items-center w-full lg:w-[150px] h-[48px] bg-[#e0e0e0] overflow-hidden rounded-[0.5rem] relative'>
                                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                        </div>
                                    </div>
                                </div>      
                            </section>
                        </>
                    ) : (
                        <>
                            {lastPosts?.pages?.[0]?.data?.length > 0 ? (
                                <>
                                    {lastPosts && lastPosts?.pages.map(pageData => (
                                        <React.Fragment key={pageData.pageNumber}>
                                            {pageData?.data.map((data,index) => (
                                                <ArticlesBox data={data} key={Math.random()}/>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </>
                            ) : (
                                <div className='w-full py-[5rem] flex justify-center items-center flex-col'>
                                    <img src={empty} />

                                    <p>نتیجه ای یافت نشد.</p>

                                    <Link to={'/shop'} className='text-[#089584] text-[12px] lg:text-[14px] px-[0.75rem] py-[0.5rem] lg:px-[1.5rem] lg:py-[0.75rem] bg-[#dcf8f3] rounded-[0.5rem] mt-[1rem]'>بازگشت به فروشگاه</Link>
                                </div>
                            )}
                        </>
                    )}
                </section>
            </main>
        </>
    );
};

export default Articles;