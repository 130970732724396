import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor } from '../../Store/Slices/productSlice';

const Form = ({data}) => {
    const selectedColor = useSelector((state) => state.product.selectedColor);
    const dispatch = useDispatch();
    const [price, setPrice] = useState('unavailable');

    const handleColorChange = (color) => {
        dispatch(selectColor(color));
    };

    useEffect(() => {
        if (selectedColor?.status === 'unavailable' || !selectedColor) {
            setPrice('unavailable');
        } else {
            setPrice(selectedColor);
        }
    }, [selectedColor]);

    return (
        <>
            <div className='mt-[1rem]'>
                <p className='text-[16px] mb-[0.5rem] text-[#0C0C0C] font-[600]'>{price?.title}</p>

                <div className='flex justify-start items-center'>
                    {data?.colors && Array.isArray(data?.colors) ? (
                        data?.colors.map((data,index) => (
                            <button key={index} onClick={(e) => handleColorChange(data)} className={`color-product-input ${data.id === price.id ? `color-product-input-active` : 'bg-[#FFFFFF]'}`} style={data?.id === price?.id ? { backgroundColor: data?.value } : {}}>
                                <div style={{ backgroundColor: data?.value }}>
                                    <svg className='hidden' width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00039 11.2001L1.80039 7.0001L0.400391 8.4001L6.00039 14.0001L18.0004 2.0001L16.6004 0.600098L6.00039 11.2001Z" fill="white"/>
                                    </svg>
                                </div>
                            </button>
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
            </div>
        </>
    );
};

export default Form;