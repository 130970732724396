import React from 'react';
import DailyAuction from '../../Components/Shop/DailyAuction';
import Category from '../../Components/Shop/Category';
import Futures from '../../Components/Shop/Futures';
import BestSellers from '../../Components/Shop/BestSellers';
import { useQuery,useQueries } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import LastProduct from '../../Components/Shop/LastProduct';
import CategoryList from '../../Components/Shop/CategoryList';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';

const fetchHome = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(token)
    {
        myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/home?slug[0]=daily_auction&slug[1]=categories&slug[2]=best_sellers`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchSliderHome = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/banners`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const fetchCategoryProducts = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/categories?type=product`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const Shop = () => {
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;

    
    const { data : metaTga, error, isLoadingMetatag } = useQuery(['faq'], fetchmetaTga);

    const results = useQueries([
        { queryKey: ['home'], queryFn: () => fetchHome(token) },
        { queryKey: ['homeSlider'], queryFn: fetchSliderHome },
        { queryKey: ['CategoryProducts'], queryFn: fetchCategoryProducts }
    ]);

    const [home, homeSlider, CategoryProducts] = results;

    const isLoading = home.isLoading || homeSlider.isLoading || CategoryProducts.isLoading;
    const isError = home.isError || homeSlider.isError || CategoryProducts.isError;

    return (
        <>
            <Helmet>
                <title>فروشگاه - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="فروشگاه - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            {isLoading && (
                <Loading />
            )}

            <main>
                <CategoryList data={CategoryProducts?.data?.data} />

                <LastProduct />
                
                <Category data={CategoryProducts?.data?.data} />

                <BestSellers data={home?.data?.data?.best_sellers} />

                <DailyAuction data={home?.data?.data?.daily_auction} />

                <Futures />
            </main>
        </>
    );
};

export default Shop;