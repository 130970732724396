import { useState, useEffect } from "react";
import moment from "moment-jalaali";

const useJalaliDate = (gregorianDate) => {
    const [jalaliDate, setJalaliDate] = useState("");

    useEffect(() => {
        if (gregorianDate) {
            moment.locale("fa");
            moment.loadPersian({ usePersianDigits: true });

            const formattedDate = moment(gregorianDate).format("jD jMMMM jYYYY");

            const correctedDate = formattedDate
                .replace("امرداد", "مرداد")
                .replace("امرداد", "مرداد")
                .replace("امهر", "مهر")
                .replace("اردیبهشت", "اردیبهشت")
                .replace("آذر", "آذر")
                .replace("بهمن", "بهمن")
                .replace("اسفند", "اسفند")

            setJalaliDate(correctedDate);
        }
    }, [gregorianDate]);

    return jalaliDate;
};

export default useJalaliDate;