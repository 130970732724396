import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery,useQueries } from 'react-query';

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Privacy = () => {
    const { data : metaTga, error, isLoadingMetatag } = useQuery(['faq'], fetchmetaTga);
    return (
        <>
            <Helmet>
                <title>سیاست حریم خصوصی - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="سیاست حریم خصوصی - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main className='px-[2rem] lg:px-[4rem] py-[2rem]'>
                <div className='mb-[2rem]'>
                    <h3 className='text-[#0C0C0C] text-[30px] font-[700]'>سیاست حریم خصوصی</h3>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>ما که هستیم؟</p>

                    <p>نشانی وب‌سایت ماhttps://www.neireez.com/  است. در زمان خرید به آدرس دقیق سایت فروشگاه ما دقت بفرمایید. هم اکنون در حال مطالعه ی متن سیاست حفظ حریم خصوصی ما هستید.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>ارتباط و افشای اطلاعات شخصی به اشخاص ثالث</p>

                    <p>اطلاعات شخصی شما (از جمله ایمیل شما) هرگز برای اهداف بازاریابی با هیچ شخص ثالثی به اشتراک گذاشته نمی شود.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>کدام اطلاعات شخصی را جمع آوری می کنیم؟</p>

                    <p>انواع داده هایی که برای مقاصد مشخص جمع آوری می کنیم عبارتند از: نام ، شماره تلفن ، نقش شغلی ، کشور محل سکونت ، آدرس IP ، آدرس ایمیل و سایر داده هایی که در اعلامیه حریم خصوصی مشخص شده است و در کنار هر فرم که اطلاعات شخصی شما را در آنجا جمع آوری می کنیم مشخص شده است.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>هدف و نحوه جمع آوری و استفاده از داده‌های شخصی</p>

                    <p>نیریز اطلاعات شخصی را مطابق مفاد مقررات عمومی حفاظت از داده های کشور ایران را جمع آوری و پردازش می کند. <br />
                        داده های شخصی شما فقط بر اساس تأیید شما و رضایت صریح و رایگان برای پردازش داده های شخصی شما برای اهداف مربوط به استفاده از محتوا موجود از طریق وب سایت ، پردازش می شود. هنگام تکمیل فرم مناسب منتشر شده در وب سایت ، رضایت شما برای جمع آوری و پردازش داده های شخصی شما برای یک هدف معین درخواست می شود که شما با تایید آن به نیریز  ، اجازه آنرا صادر کرده اید.<br />
                        از اطلاعات شخصی شما برای ارائه محتوا ، پاسخ به پرسش های مربوط به محتوای موجود در وب سایت ، اطلاع رسانی به شما در مورد مطالب موجود ، جدید ، مواد ، عملکردها ، خدمات و سایر پیشنهادات مورد علاقه شما و به منظور بهبود کیفیت محتوا و وب سایت استفاده می شود. شما می توانید افشای دقیق هدف از جمع آوری داده های شخصی خود را در اعلامیه حریم خصوصی که در کنار هر شکلی که داده های شخصی شما را جمع آوری می کنیم نمایش داده شود.<br />
                        همه داده های جمع آوری شده به صورت الکترونیکی ذخیره می شوند و اقدامات و روش های مناسب به منظور جلوگیری از دسترسی غیر مجاز ، حفظ سطح حفاظت از داده های شخصی و استفاده از داده های جمع آوری شده آنلاین به شیوه مناسب اعمال می شود.<br />
                        اگرچه ما تمام اقدامات مناسب را برای اطمینان از افشای غیرمجاز اطلاعات شخصی شما انجام می دهیم ، اما نمی توانیم تضمین کنیم که برخی از اطلاعات شخصی جمع آوری شده هرگز به روشی که مطابق با این شرایط نیست افشا شود. افشای تصادفی ممکن است ، به عنوان مثال ، پیامد نادرست دروغین هنگام دسترسی به وب سایتی باشد که حاوی چنین داده هایی هستند ، با هدف تصحیح خطاهای احتمالی در داده ها. مسئولیت ما باید تا حد مجاز توسط قانون در مورد هرگونه خسارت وارده به کاربران یا اشخاص ثالث در ارتباط با افشای تصادفی اطلاعات شخصی محدود شود.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>جمع آوری و پردازش داده‌های شخصی توسط سایر کاربران یا اشخاص ثالث</p>

                    <p>وب سایت حاوی محتوای تعاملی هستند و ممکن است حاوی پیوندهایی به وب سایتی شخص ثالث باشند که از طریق آنها سایر کاربران یا اشخاص ثالث ممکن است به داده های شخصی شما دسترسی مجاز یا غیرمجاز داشته باشند. این شرایط برای جمع آوری ، پردازش یا استفاده از داده های شخصی که به سایر کاربران و/یا اشخاص ثالث ارسال می کنید ، اعمال نمی شود. به نفع شماست که خودتان را با قوانین حفاظت از داده های شخصی و حفاظت از حریم خصوصی اعمال شده توسط سایر کاربران و/یا اشخاص ثالث آشنا کنید. از آنجا که ما نمی توانیم داده هایی را که هنگام دسترسی یا استفاده از محتواهای تعاملی ، پیوندها یا وب سایتی شخص ثالث ارائه می دهید یا در شرایط دیگری که داده های شخصی خود را به سایر کاربران و/یا اشخاص ثالث ارسال می کنید ، کنترل کنیم ، ما هیچ مسئولیتی در قبال هیچ خسارتی که به شما ، سایر کاربران و/یا اشخاص ثالث وارد می شود ، ناشی از این واقعیت است که شما داده های شخصی خود را در ارتباط با استفاده از وب سایت ارسال کرده اید.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>اصلاح و حذف اطلاعات شخصی</p>

                    <p>شما از نظر قانونی و سیاست حفظ حریم خصوصی سایت ما می توانید در هر زمان درخواست تغییر یا حذف داده های شخصی خود یا حذف از پایگاه داده کاربر ثبت شده را داشته باشید. اصلاح یا حذف داده ها باید بر اساس یک اعلان مناسب به مخاطب مشخص شده در وب سایت انجام شود.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>کوکی‌ها</p>

                    <p className='text-[19px] font-[500]'>کوکی‌ها</p>

                    <p>کوکی یک فایل کوچک از حروف و اعداد است که ما در مرورگر شما یا هارد دیسک دستگاه شما ذخیره می کنیم ، مانند حافظه کامپیوتر.</p>

                    <p className='text-[19px] font-[500]'>از 	چه کوکی‌هایی استفاده می کنیم و چرا؟</p>

                    <p>به طور کلی ، وب سایت از کوکی ها استفاده می کند تا شما را از سایر کاربران وب سایت متمایز کند. این به ما کمک می کند تا هنگام مرور وب سایت ، تجربه خوبی را در اختیار شما قرار دهیم و همچنین به ما امکان می دهد آن را بهبود بخشیم. کوکی هایی که ممکن است در وب سایت استفاده کنیم ممکن است به شرح زیر طبقه بندی شوند:شدیدا ضروری است</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>از 	چه کوکی‌هایی استفاده می کنیم و چرا؟</p>

                    <p>به طور کلی ، وب سایت از کوکی ها استفاده می کند تا شما را از سایر کاربران وب سایت متمایز کند. این به ما کمک می کند تا هنگام مرور وب سایت ، تجربه خوبی را در اختیار شما قرار دهیم و همچنین به ما امکان می دهد آن را بهبود بخشیم. کوکی هایی که ممکن است در وب سایت استفاده کنیم ممکن است به شرح زیر طبقه بندی شوند:</p>

                    <ul>
                        <li>شدیدا ضروری است</li>

                        <li>کارایی</li>

                        <li>عملکرد</li>

                        <li>هدف‌گذاری</li>
                    </ul>

                    <p>رخی از کوکی ها ممکن است بیش از یکی از این اهداف را برآورده کنند.
                    کوکی های “بسیار ضروری” به شما امکان می دهد در وب سایت حرکت کرده و از ویژگی های ضروری مانند مناطق امن استفاده کنید. بدون این کوکی ها ، ما نمی توانیم خدمات درخواستی را ارائه دهیم. استفاده از کوکی ها مغایرتی با سیاست حفظ حریم خصوصی کاربران ندارد.
                    ما از این کوکی های بسیار ضروری برای موارد زیر استفاده می کنیم</p>

                    <ul>
                        <li>شناسایی کنیم که وارد وب سایت شده اید و هویت شما را تأیید می کند.</li>

                        <li>برای اهداف امنیتی</li>

                        <li>وقتی تغییری در نحوه کار ما ایجاد می شود ، مطمئن شوید که به سرویس مناسب در وب سایت متصل شده اید. اگر از این کوکی ها جلوگیری کنید ، نمی توانیم عملکرد وب سایت یا امنیت وب سایت را در طول بازدید شما تضمین کنیم.</li>
                    </ul>

                    <p>کوکی های “عملکرد” ​​اطلاعات مربوط به نحوه استفاده از وب سایت را جمع آوری می کنند ، به عنوان مثال از چه صفحاتی دیدن می کنید و در صورت بروز خطا. این کوکی ها هیچ اطلاعاتی را که می تواند شما را شناسایی کند ، جمع آوری نمی کند و فقط برای کمک به ما در بهبود نحوه عملکرد وب سایت ، درک علایق کاربران و اندازه گیری اثربخشی تبلیغات ما استفاده می شود. ما از کوکی های  برای بهبود عملکرد سایت نیریز   استفاده می کنیم.</p>

                    
                    <p className='text-[19px] font-[500]'> نحوه حذف و مسدود کردن کوکی‌های ما</p>

                    <p>اکثر مرورگرهای وب امکان کنترل بیشتر کوکی ها را از طریق تنظیمات مرورگر می دهند. با این حال ، اگر از تنظیمات مرورگر خود برای مسدود کردن همه کوکی ها (از جمله کوکی های بسیار ضروری) استفاده می کنید ، ممکن است نتوانید به تمام یا بخشی از وب سایت دسترسی پیدا کنید. مگر اینکه تنظیمات مرورگر خود را طوری تنظیم کرده باشید که کوکی ها را رد کند ، سیستم ما به محض بازدید از وب سایت کوکی ها را صادر می کند.
                    تغییر تنظیمات کوکی تنظیمات مرورگر برای تغییر تنظیمات کوکی های شما معمولاً در منوی “options” یا “preferences” مرورگر اینترنت شما یافت می شود.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>زمان نگهداری</p>

                    <p>هرگونه اطلاعات شخصی که برای اطلاع رسانی به روز رسانی خدمات و بازاریابی در اختیار ما قرار دارد تا زمانی که شما به ما اطلاع دهید که دیگر مایل به دریافت این اطلاعات نیستید ، در اختیار ما خواهد بود.</p>

                    <p>ما اطلاعات شما را در مدت زمان فعال شدن اشتراک شما و 90 روز پس از لغو اشتراک در وب سایت خود نگه می داریم. پس از این مدت ، داده های شما برگشت ناپذیر حذف می شوند. همچنین می توانید داده های خود را قبل از پایان دوره 90 روزه بر اساس درخواست شما حذف کنید.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>حقوق شما</p>

                    <p>با کلیک روی پیوند لغو اشتراک در پاورقی یک ایمیل ، می توانید اشتراک هر نوع پیام های ایمیل نیریز   را لغو کنید. این در راستای سیاست حفظ حریم خصوصی خریداران و مشتریان ما می باشد.</p>

                    <p>اگر فکر می کنید که هر گونه اطلاعات شخصی ما در مورد شما نادرست یا ناقص است ، شما حق دارید که برای دیدن این اطلاعات ، اصلاح یا حذف آنها درخواست کنید. برای استفاده از این حقوق ، لطفاً با تیم پشتیبانی ما تماس بگیرید.</p>

                    <p>در صورت تمایل به شکایت در مورد نحوه مدیریت اطلاعات شخصی شما ، لطفاً با تیم پشتیبانی ما تماس بگیرید. سپس شکایت شما را بررسی کرده و برای حل این مشکل با شما همکاری خواهیم کرد.</p>

                    <p>اگر هنوز احساس می کنید که اطلاعات شخصی شما مطابق قانون مورد رسیدگی قرار نگرفته است ، می توانید با ما تماس بگیرید.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>شرایط استفاده برای الگوهای اسناد</p>

                    <p>سند/اسنادی که از طریق وب سایت فروخته می شود توسط حق چاپ توسط نیریز   محافظت می شود.
                        پس از پرداخت ، نیریز به شما حق غیر انحصاری ، دائمی و محدودی برای استفاده از سند/اسناد را در سراسر جهان ، منحصراً به روشی که در اینجا مشخص شده است ، اعطا می کند. کلیه حقوقی که در اینجا به صراحت اعطا نشده است محفوظ است.
                        شما مجاز هستید که از سند/اسناد به هر منظور ، اما منحصراً برای نیازهای کسب و کار خود ، به هر نحوی که مطابق با حقوق اعطا شده باشد ، استفاده کنید ، تا سند یا اسناد را به طور کلی یا جزئی ، مستقیم یا غیر مستقیم کپی کنید. به طور موقت یا دائمی ، به هر وسیله و به هر شکلی ، و همچنین ترجمه آن به زبان های دیگر ، برای تطبیق ، ویرایش و یا اصلاح سند یا اسناد.
                        استفاده از سند/اسناد به صورت تجاری برای هر منظور یا نیازی که مستقیماً به تجارت داخلی شما مربوط نمی شود ، صراحتا ممنوع است. اجاره یا توزیع اصل یا نسخه های سند/اسناد با فروش یا غیره ، یا ارائه آنها به قصد فروش ، و همچنین چاپ ، انتشار و در دسترس عموم از طریق سیم یا بی سیم ممنوع است. با اجازه دادن به اشخاص ثالث غیرمجاز برای دسترسی به اسناد از مکان ها و در مواقع دلخواه خود (به عنوان مثال انتشارات اینترنتی) ، بدون اخذ رضایت کتبی از نیریز  .
                        شما مجاز به انتقال حقوق خود به اشخاص ثالث حقوقی یا حقیقی مرتبط ، یا غیر مرتبط ، از جمله شرکتهای وابسته خود ، و یا اعطای هرگونه حق استفاده از این افراد بدون رضایت کتبی که قبلاً توسط نیریز اعطا شده است.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>پرداخت معتبر</p>

                    <p>سند/اسنادی که از طریق وب سایت فروخته می شود توسط حق چاپ توسط نیریز محافظت می شود.
                    پس از پرداخت ، نیریز به شما حق غیر انحصاری ، دائمی و محدودی برای استفاده از سند/اسناد را در سراسر جهان ، منحصراً به روشی که در اینجا مشخص شده است ، اعطا می کند. کلیه حقوقی که در اینجا به صراحت اعطا نشده است محفوظ است.
                    شما مجاز هستید که از سند/اسناد به هر منظور ، اما منحصراً برای نیازهای کسب و کار خود ، به هر نحوی که مطابق با حقوق اعطا شده باشد ، استفاده کنید ، تا سند یا اسناد را به طور کلی یا جزئی ، مستقیم یا غیر مستقیم کپی کنید. به طور موقت یا دائمی ، به هر وسیله و به هر شکلی ، و همچنین ترجمه آن به زبان های دیگر ، برای تطبیق ، ویرایش و یا اصلاح سند یا اسناد.
                    استفاده از سند/اسناد به صورت تجاری برای هر منظور یا نیازی که مستقیماً به تجارت داخلی شما مربوط نمی شود ، صراحتا ممنوع است. اجاره یا توزیع اصل یا نسخه های سند/اسناد با فروش یا غیره ، یا ارائه آنها به قصد فروش ، و همچنین چاپ ، انتشار و در دسترس عموم از طریق سیم یا بی سیم ممنوع است. با اجازه دادن به اشخاص ثالث غیرمجاز برای دسترسی به اسناد از مکان ها و در مواقع دلخواه خود (به عنوان مثال انتشارات اینترنتی) ، بدون اخذ رضایت کتبی از نیریز  .
                    شما مجاز به انتقال حقوق خود به اشخاص ثالث حقوقی یا حقیقی مرتبط ، یا غیر مرتبط ، از جمله شرکتهای وابسته خود ، و یا اعطای هرگونه حق استفاده از این افراد بدون رضایت کتبی که قبلاً توسط نیریز اعطا شده است.</p>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>قانون حاکم</p>

                    <p>سیاست حفظ حریم خصوصی سایت نیریز  مطابق قوانین جمهوری اسلامی ایران ، تدوین و مورد تایید طرفین قرار گرفته است.
                    عدم اجرای هرگونه حق یا مفاد این شرایط ، چشم پوشی از این حقوق تلقی نمی شود. اگر هر یک از مفاد این شرایط توسط دادگاه معتبر یا غیرقابل اجرا تشخیص داده شود ، سایر مفاد این شرایط به قوت خود باقی است. این شرایط کل توافق بین ما در مورد وب سایت نیریز  را تشکیل می دهد و جایگزین هرگونه توافق قبلی می شود که ممکن است در مورد وب سایت نیریز   و شما وجود داشته باشد.</p>
                </div>
            </main>
        </>
    );
};

export default Privacy;