import React from 'react';
import VectorLeft from './../../Assets/Images/Faq/vector-left.svg';
import { useQuery,useQueries } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import { Helmet } from 'react-helmet-async';


const fetchFaq = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/faq`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Faq = () => {
    const { data : faq, error, isLoading } = useQuery(['faq'], fetchFaq);
    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);

    const dropDown = (e) => {
        document.querySelectorAll('.drop-down-faq')[e].classList.toggle('hidden');
        document.querySelectorAll('.drop-down-faq-header')[e].classList.toggle('active-drop-down-faq-header');
    }

    return (
        <>
            <Helmet>
                <title>پرسش‌های متداول - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="پرسش‌های متداول - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            {isLoading && (
                <Loading />
            )}

            <main>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <h3 className='text-[28px] text-[#0C0C0C] font-[700]'>پرسش‌های متداول</h3>
                </section>
                
                <section className='px-[2rem] lg:px-[4rem] my-[2rem] flex justify-between items-center flex-col-reverse lg:flex-row'>
                    <section className='w-full'>
                        {faq?.data && Array.isArray(faq?.data) ? (
                            faq?.data.map((data,index) => (
                                <div className='border-b border-[#E5E5E5]'>
                                    <div onClick={(e) => dropDown(index)} className='drop-down-faq-header flex justify-between items-center cursor-pointer py-[1rem]'>
                                        <p className='text-[#1A1A1A] text-[20px] font-[600]'>{ data?.question }</p>

                                        <svg className='transition-[0.3s] min-w-[32px]' width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.2762 12.8902L8.39062 14.7758L15.0573 21.4425C15.578 21.9632 16.4222 21.9632 16.9429 21.4425L23.6096 14.7758L21.724 12.8902L16.0001 18.6141L10.2762 12.8902Z" fill="#424750"/>
                                        </svg>
                                    </div>

                                    <div className='drop-down-faq hidden text-[#424750] text-[18px] font-[400] text-justify pb-[1rem]' dangerouslySetInnerHTML={{ __html : data?.answer }}></div>
                                </div>
                            ))
                        ) : (
                            <p>محتوایی برای نمایش یافت نشد</p>
                        )}
                    </section>

                    <section className='w-[8rem] mb-[1rem] lg:w-[22rem] lg:min-w-[22rem] lg:mr-[4rem]'>
                        <img className='w-full' src={VectorLeft} />
                    </section>  
                </section>
            </main>
        </>
    );
};

export default Faq;