import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery,useQueries } from 'react-query';

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Policy = () => {
    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);
    return (
        <>
            <Helmet>
                <title>قوانین - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="قوانین - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main className='px-[2rem] lg:px-[4rem] py-[2rem]'>
                <div className='mb-[2rem]'>
                    <h3 className='text-[#0C0C0C] text-[30px] font-[700]'>قوانین و مقررات</h3>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'> استفاده از فروشگاه اینترنتی نیریز</p>

                    <p>استفاده شما از فروشگاه اینترنتی “نیریز” و سفارش کالا از این پایگاه به معنی توافق کامل شما با شرایط و ضوابط ذیل تلقی می گردد:</p>

                    <ul>
                        <li>خرید کالا از فروشگاه اینترنتی “نیریز” بر مبنای قوانین و آئین نامه های موجود در مورد تجارت الکترونیک و با رعایت کامل تمام قوانین جمهوری اسلامی ایران صورت می پذیرد.</li>

                        <li>اطلاعات و مشخصات کالاهای عرضه شده در فروشگاه اینترنتی “نیریز” از منابع زیر تامین می‌شود:</li>
                    </ul>

                    <p>1-  اطلاعاتی که توسط نمایندگی های کالا ارائه می‌شوند. <br/> 
                    2-  اطلاعاتی که از سایت‌های معتبر خارجی ترجمه می‌شوند</p>

                    <p>از این رو فروشگاه اینترنتی “نیریز” هیچ مسوولیتی در قبال اطلاعات و محتوای مبهم و یا خطاهای نگارشی احتمالی برعهده نخواهد داشت. ولی با هدف ارائه خدمات بهتر و آگاه‌سازی مشتریان، خود را در بروزرسانی مداوم اطلاعات و محتویات متعهد می‌داند.</p>

                    <ul>
                        <li>فروشگاه اینترنتی “نیریز” هیچ گونه مسئولیتی را در مورد کارکرد سایت که می تواند ناشى از عواملی که خارج از سیطره مدیریت این فروشگاه می باشند ( مانند نقص اینترنت، مسائل مخابراتى، تجهیزات سخت افزاری و غیره) نمی پذیرد.</li>

                        <li>فروشگاه اینترنتی “نیریز” به هیچ وجه اطلاعات منحصر بفرد کاربران را به اشخاص و طرفین غیر، واگذار نخواهد کرد و ضمنا با استفاده از آخرین فن آوری ها متعهد است حتی المقدور از حریم شخصی کاربران دفاع کند.</li>

                        <li>خدمات و محتویات سایت، صرفا براى استفاده شخصى و غیر تجارى شما عرضه شده است. هر گونه سوء استفاده از این اطلاعات پیگرد قانونی خواهد داشت و هیچ فرد حقیقی، حقوقی یا سایتی به هیچ عنوان نمی تواند از اطلاعات و محتویات موجود در سایت براى فروش، تولید مجدد یا ایجاد ترافیک در سایت خود استفاده نماید. اگر مایلید از سرویسها و محتویات سایت جهت مقاصد تجارى استفاده نمایید ، لازم است از قبل به ما اطلاع دهید. براى این کار لطفا با ما تماس بگیرید.</li>

                        <li>کاربران باید هنگام سفارش کالای مورد نظر خود، فرم سفارش را با اطلاعات صحیح و به طور کامل پر نمایند. بدیهی است درصورت ورود اطلاعات ناقص یا نادرست، سفارش کاربر قابل پیگیری و تحویل نخواهد بود.</li>

                        <li>مالکیت معنوی محتویات فروشگاه اینترنتی “نیریز” شامل قانون حق تکثیر بوده و متعلق به فروشگاه اینترنتی “نیریز”می باشد.</li>
                    </ul>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>فروش و تحویل سفارش</p>
                    
                    <ul>
                        <li> در صورتی که نیریز به دلایلی خارج از اراده و اختیار خود، توان تحویل کالا در موعد مقرر را نداشته باشد و طرفین درباره یک موعد جدید به توافق نرسند، خریدار حق دارد بدون هیچگونه هزینه ای سفارش را فسخ نماید.</li>
                        
                        <li>ارزش کالا یا کالاهای سفارش داده شده طبق فهرست قیمت سایت محاسبه شده و شامل کلیه مالیات ها و عوارض فروش خواهد بود. هزینه مالیات بر ارزش افزوده در صورت عدم قید شدن در سایت صفر ریال محاسبه خواهئ شد. تنها مبلغی که در موارد معین به ارزش کالا اضافه می شود، هزینه ارسال و بیمه کالا خواهد بود.</li>

                        <li>مدت گارانتی و نام شرکت ارائه دهنده خدمات پس از فروش در قسمت توضیحات هر کالا ذکر شده است. در صورت عدم درج موارد مذکور در این قسمت، گارانتی برای آن کالا وجود ندارد.</li>
                    </ul>
                </div>

                <div className='li-style-text text-justify'>
                    <p className='font-bold my-[0.5rem] text-[22px]'>شرایط برگشت کالا</p>

                    <ul>
                        <li>هرگونه نقص و عیب فنی کالا، باید در مدت 48 ساعت پس از دریافت کالا حتی در روزهای تعطیل از طریق تلفن 09369148872 و یا ایمیل  info@neireez.com به مرکز ارتباط مشتریان فروشگاه اطلاع داده شود تا مشکل فوراً بررسی و در صورت اثبات نقص، تعمیر یا جایگزین گردد. بدیهی است پس از 48 ساعت هیچ اعتراضی پذیرفتنی نیست.(خاطرنشان می سازد این بند فقط برای کالاهای فاقد گارانتی صدق خواهد کرد و کالاهای دارای گارانتی از لحظه فروش مشمول ضمانت بوده و نقایص احتمالی توسط شرکت ارائه کننده گارانتی پیگیری میگردد)</li>

                        <li>در صورتی که فرد خریدار بخواهد وجه کالا را به طور ناقص پرداخت کند کالا بدون نیریزهنگی، توسط پیک برگشت خواهد خورد و هزینه ارسال نیز عودت داده نخواهد شد.</li>

                        <li>خریدار به مدت 7 روز کاری فرصت دارد از سفارش خود انصراف دهد، به شرط آنکه بسته بندی کالایی باز نشده و از آن هیچ گونه استفاده‌ای نشده باشد. لازم به ذکر است هزینه پست و بیمه به عهده خریدار میباشد.</li>

                        <li>این انصراف برای کالاهای زیر امکان پذیر نمی باشد:</li>
                    </ul>

                    <p>–1-  کالاهایی که قیمت آنها توسط نوسانات بازارهای مالی و ارزی تعیین می شود.<br /> –2-  کالا های ابزار دقیق و اندازه گیری که از حساسیت خاصی برخوردار هستند.</p>

                    <ul>
                        <li>عدم انطباق کالای ارسال شده با سفارش شما، در مدت 24 ساعت پس از دریافت کالا حتی در روزهای تعطیل از طریق تلفن 09369148872  یا ایمیل info@neireez.com یا به مرکز ارتباط مشتریان فروشگاه اطلاع داده شود تا مشکل فوراً بررسی و در صورت اثبات، کالا بدون دریافت هیچ هزینه ای مجددا برای شما ارسال شود. بدیهی است عدم اطلاع رسانی پس از 24 ساعت، به معنای صحت اقلام دریافتی است.</li>
                    </ul>
                </div>
            </main>
        </>
    );
};

export default Policy;