import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Articles from './../../Components/Articles/Articles';
import { QueryClient, QueryClientProvider, useInfiniteQuery,useQuery } from 'react-query';
import { useLocation, Link,useParams,useNavigate } from 'react-router-dom';
import Loading from '../../Components/Leyouts/Home/Loading';
import useJalaliDate from '../../Logics/useJalaliDate';
import { Helmet } from 'react-helmet-async';

const fetchData = async (search = "",) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/posts/${search}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchDataRelated = async (category,ignor) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/posts?categories[0]=${category}&ignore[0]=${ignor}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const Article = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const { id } = useParams();
    const [search, setSearch] = useState(id);

    useEffect(() => {
        const newSearch = queryParams.get('search') || id;
        setSearch(newSearch);
    }, [location.search, id]);

    const { data: post, isLoading, error, refetch } = useQuery(
        ['data', search], 
        () => fetchData(search), 
        {
            refetchOnWindowFocus: false,
            onError: () => {
                navigate('/404');
            },
        }
    );

    const { data: related, isLoadingRelated, errorRelated } = useQuery(
        ['data', post?.data?.categories?.[0]?.id, post?.data?.id], 
        () => fetchDataRelated(post?.data?.categories?.[0]?.id, post?.data?.id), 
        {
            refetchOnWindowFocus: false,
            enabled: !!post && !!post.data && !!post.data.categories && post.data.categories.length > 0,
        }
    );

    useEffect(() => {
        refetch();
    }, [search]);

    if(post == undefined && isLoading != true)
    {
        navigate('/404');
    }

    return (
        <>
            <Helmet>
                <title>{post?.data?.title + ' - نیریز'}</title>
                <meta name="keywords" content={post?.data?.seo_keywords} />
                <meta name="description" content={post?.data?.seo_description} />
                <meta property="og:title" content={post?.data?.title + ' - نیریز'} />
                <meta property="og:description" content={post?.data?.description} />
            </Helmet>

            {isLoading && (
                <Loading />
            )}
            
            <main>
                <div className='flex justify-start items-center flex-col'>
                    <section className='lg:w-[85%] flex justify-start items-center px-[2rem] lg:px-[4rem] pt-[2rem] pb-[2.5rem]'>
                        <Link className='text-[#81858B] text-[12px] lg:text-[14px] font-[400]'>مقالات</Link>

                        <span className='px-[1rem] text-[#81858B] text-[12px] lg:text-[14px] font-[400]'>/</span>

                        {post?.data?.categories && (
                            <>
                                <Link to={`/articles?category=${post?.data?.categories[0]?.id}`} className='text-[#81858B] text-[12px] lg:text-[14px] font-[400]'>{post?.data?.categories[0]?.title}</Link>

                                <span className='px-[1rem] text-[#81858B] text-[12px] lg:text-[14px] font-[400]'>/</span>
                            </>
                        )}

                        <Link className='text-[#81858B] text-[12px] lg:text-[14px] font-[400]'>{post?.data?.title}</Link>
                    </section>

                    <section className='lg:w-[85%] px-[2rem] lg:px-[4rem]'>
                        <div className='mb-[2rem]'>
                            <h4 className='text-[#0C0C0C] font-[700] text-[28px]'>{post?.data?.title}</h4>
                        </div>

                        <div className='w-full'>
                            <img className='w-full max-h-[35rem] overflow-hidden object-cover rounded-[1rem]' src={post?.data?.media?.original} />
                        </div>

                        <div className='flex justify-start items-center my-[1rem]'>
                            <div className='w-[3.5rem] h-[3.5rem] min-w-[3.5rem] lg:w-[4rem] lg:h-[4rem] lg:min-w-[4rem]'>
                                <img className='w-full h-full rounded-full' src={post?.data?.author?.image?.path} />
                            </div>

                            <div className='w-full flex-row lg:flex-col flex justify-between lg:justify-center items-start mr-[12px]'>
                                <p className='text-[#3C3C3C] text-[14px]'>{post?.data?.author?.name}</p>

                                <p className='text-[#626262] text-[12px] mt-[0.25rem]'>{ useJalaliDate(post?.data?.published_at) }</p>
                            </div>
                        </div>

                        <div className='h-full overflow-hidden text-justify' dangerouslySetInnerHTML={{ __html : post?.data?.body }}></div>
                    </section>
                </div>

                <section>
                    {related?.data && Array.isArray(related?.data) ? (
                        <Articles data={related?.data} />
                    ) : (
                        <p></p>
                    )}
                </section>
            </main>
        </>
    );
};

export default Article;