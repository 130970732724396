import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import useAuthRedirect from '../../Logics/useAuthRedirect';
import { useCookies } from 'react-cookie';
import ProductBox from '../../Components/Dashbord/ProductBox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartFromAPI } from '../../Store/Slices/cartSlice';
import { Link, useNavigate } from 'react-router-dom';
import empty from './../../Assets/Images/Cart/empty-cart.svg';
import { fetchWishListFromAPI } from '../../Store/Slices/WishlistSlice';
import { Helmet } from 'react-helmet-async';

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const MyWishlist = () => {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['authToken']);
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.user);
    const WishList = useSelector((state) => state.WishList);
    var token = cookies.authToken;
    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);

    useEffect(() => {
        if (token) {
            dispatch(fetchWishListFromAPI(token));
        }
    }, [dispatch, token]);
    
    const navigate = useNavigate();

    const handelAddToCart = () => {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        if(token && user?.profile?.data)
        {
            myHeaders.append("Authorization", `Bearer ${token}`);
        }

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/orders/cart/wishlist-to-cart", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status === 200) {
                toast.success('محصول با موفقیت به سبد خرید اضافه شد',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }

            navigate('/cart');

            setLoading(false);
        })
        .catch((error) => console.error(error));
    }
    
    return (
        <>
            <Helmet>
                <title>علاقه‌مندی‌ها - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="علاقه‌مندی‌ها - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <section className='w-full lg:border border-[#F0F0F0] rounded-[0.5rem] mt-[1rem] lg:mt-0 lg:mr-[3rem] lg:px-[2rem] lg:py-[1rem]'>
                <div className='flex-col lg:flex-row flex justify-between items-center'>
                    <h3 className='text-[17px] lg:text-[24px] text-[#0C0C0C] font-[600] ml-[0.5rem]'>فهرست علاقه‌مندی‌ها</h3>
                    
                    {WishList?.data?.length > 0 && (
                        <button onClick={handelAddToCart} className='mt-[0.5rem] lg:mt-0 btn-hover flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                            <svg className='lg:ml-[0.5rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 2.5H5.5V5H8V7H5.5V9.5H3.5V7H1V5H3.5V2.5Z" fill="white"/>
                            <path d="M10 7H19.0002V15H5.90514L5.55514 11.5H3.54517L4.00512 16.0995C4.05624 16.6107 4.4864 17 5.00015 17H20.0002C20.5524 17 21.0002 16.5523 21.0002 16V5H23.0002V3H20.0002C19.4479 3 19.0002 3.44772 19.0002 4V5H10V7Z" fill="white"/>
                            <path d="M6.00006 22C4.89549 22 4.00006 21.1046 4.00006 20C4.00006 18.8954 4.89549 18 6.00006 18C7.10463 18 8.00006 18.8954 8.00006 20C8.00006 21.1046 7.10463 22 6.00006 22Z" fill="white"/>
                            <path d="M17.0001 20C17.0001 21.1046 17.8955 22 19.0001 22C20.1046 22 21.0001 21.1046 21.0001 20C21.0001 18.8954 20.1046 18 19.0001 18C17.8955 18 17.0001 18.8954 17.0001 20Z" fill="white"/>
                            </svg>

                            <p>{(!loading) ? 'افزودن همه به سبد خرید' : 'صبر کنید ...'}</p>
                        </button>
                    )}
                </div>


                {WishList?.data?.length > 0 ? (
                    <div className='mt-[2rem] grid grid-cols-2 lg:grid-cols-3'>
                        {WishList?.data && Array.isArray(WishList?.data) ? (
                            WishList?.data?.map((data,index) => (
                                <ProductBox data={data} />
                            ))
                        ) : (
                            <></>
                        )}
                    </div>
                ) : (
                    <div className='min-h-[40vh] lg:min-h-[70vh] flex justify-center items-center flex-col'>
                        <img src={empty} />

                        <p>شما در لیست علاقه مندی خود محصولی ندارید .</p>

                        <Link to={'/shop'} className='text-[#089584] text-[12px] lg:text-[14px] px-[0.75rem] py-[0.5rem] lg:px-[1.5rem] lg:py-[0.75rem] bg-[#dcf8f3] rounded-[0.5rem] mt-[1rem]'>بازگشت به فروشگاه</Link>
                    </div>
                )}
            </section>
        </>
    );
};

export default MyWishlist;