import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useFormattedAmount from '../../../Logics/useFormattedAmount';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { toast } from 'react-hot-toast';
import { fetchCartFromAPI } from './../../../Store/Slices/cartSlice';
import { fetchUserProfile } from './../../../Store/Slices/userSlice';
import loadingIcon from './../../../Assets/Images/Home/fade-stagger-circles.svg';
import { setCartData } from '../../../Store/Slices/cartSlice';

const ProductBox = ({data}) => {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['authToken']);
    const cart = useSelector((state) => state.cart);
    const user = useSelector((state) => state.user);
    const [loading, setLoading] = useState(false);
    const [hasWishlist, setHasWishlist] = useState(false);

    useEffect(() => {
        if (data?.has_wishlist) {
            setHasWishlist(true);
        } else {
            setHasWishlist(false);
        }
    }, [data]);

    var token = cookies.authToken;
    let price;
    
    if (data?.default_color?.status === 'unavailable' || !data?.default_color) {
        const availableColor = data?.colors?.find(color => color.status === 'published');
        
        if (availableColor) {
            price = availableColor;
        } else {
            price = 'unavailable';
        }
    } else {
        price = data?.default_color;
    }

    let total_amount = useFormattedAmount(price?.total_amount);
    let amount = useFormattedAmount(price?.amount);
    let percen = Math.floor((price?.amount - price?.total_amount) / price?.amount * 100) + '%';

    const AddToWishlist = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const formdata = new FormData();
        formdata.append("color_id", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/wishlist/add", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                setHasWishlist(true);
                toast.success('محصول با موفقیت به لیست علاقه مندی ها اضاف شد.',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }
        })
        .catch((error) => console.error(error));
    }

    const handelAddToCart = (e,id) => {
        e.preventDefault();
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        if(token && user?.profile?.data)
        {
            myHeaders.append("Authorization", `Bearer ${token}`);
        }

        const formdata = new FormData();
        formdata.append("quantity", "1");
        formdata.append("color_id", id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/orders/cart", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status === 200) {
                toast.success('محصول با موفقیت به سبد خرید اضافه شد',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });

                dispatch(setCartData({ items: result?.data, total_amount: result?.data?.total_amount }));
            }

            setLoading(false);
        })
        .catch((error) => console.error(error));
    }

    const handelRemoveToCart = (e,id) => {
        e.preventDefault();
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        if(token && user?.profile?.data)
        {
            myHeaders.append("Authorization", `Bearer ${token}`);
        }

        const raw = JSON.stringify({
            "quantity": 1,
            "color_id":id
        });

        const requestOptions = {
            method: "PATCH",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/orders/cart/decrement", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status === 200) {
                toast.success('محصول با موفقیت از سبد خرید حذف شد',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });

                dispatch(setCartData({ items: result?.data, total_amount: result?.data?.total_amount }));
            }

            setLoading(false);
        })
        .catch((error) => console.error(error));
    }

    const handelClearToCart = (e,id) => {
        e.preventDefault();
        setLoading(true)
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");

        if(token && user?.profile?.data)
        {
            myHeaders.append("Authorization", `Bearer ${token}`);
        }
        
        const raw = JSON.stringify({
            "color_id": id
        });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/orders/cart", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                toast.success('محصول با موفقیت از سبد خرید حذف شد.',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });

                dispatch(setCartData({ items: result?.data, total_amount: result?.data?.total_amount }));
            }

            setLoading(false);
        })
        .catch((error) => console.error(error));
    }

    const RemoveToWishlist = (id) => {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${token}`);

        const raw = JSON.stringify({
            "product_id": id
        });

        const requestOptions = {
            method: "DELETE",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/wishlist/remove", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 200) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }

            if (status == 200 || status == 201) {
                setHasWishlist(false);
                toast.success('محصول با موفقیت از لیست علاقه مندی ها حذف شد.',{
                    style: {
                        maxWidth: '400px',
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                        textAlign: 'right',
                        zIndex: '10000000000'
                    },
                });
            }
        })
        .catch((error) => console.error(error));
    }
    
    return (
        <>
            <section className='bg-white border border-[#F0F0F0] p-[1rem] lg:p-[2rem] w-full relative'>
                {user?.profile && (
                    !hasWishlist ? (
                        <button onClick={(e) => AddToWishlist(data?.default_color?.id)} className='absolute right-[1rem] bg-white border border-[#0000000F] top-[1rem] z-[1] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'>
                            <svg className='mt-[0.2rem]' width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 0C3.18629 0 0.5 2.68629 0.5 6C0.5 7.80206 1.30058 9.47538 2.65631 10.6073L9.63807 17.0991C10.4057 17.8129 11.5943 17.8129 12.3619 17.099L19.3547 10.5961C20.6855 9.50406 21.5 7.81759 21.5 6C21.5 2.68629 18.8137 0 15.5 0C13.7393 0 12.116 0.765528 11 2.03031C9.88396 0.765528 8.26067 0 6.5 0ZM3.99778 9.12368L3.95388 9.08519C3.03888 8.32899 2.5 7.20961 2.5 6C2.5 3.79086 4.29086 2 6.5 2C8.04795 2 9.43585 2.8867 10.1005 4.25491C10.4652 5.00561 11.5348 5.00561 11.8995 4.25491C12.5642 2.8867 13.9521 2 15.5 2C17.7091 2 19.5 3.79086 19.5 6C19.5 7.21303 18.958 8.33528 18.0386 9.0914L11 15.6345L3.99778 9.12368Z" fill="#666666"/>
                            </svg>
                        </button>
                    ) : (
                        <button onClick={(e) => RemoveToWishlist(data?.id)} className='absolute right-[1rem] bg-white border border-[#0000000F] top-[1rem] z-[1] w-[2rem] h-[2rem] rounded-full flex justify-center items-center'>
                            <svg className='mt-[0.2rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 9C1.5 5.68629 4.18629 3 7.5 3C9.26067 3 10.884 3.76553 12 5.03031C13.116 3.76553 14.7393 3 16.5 3C19.8137 3 22.5 5.68629 22.5 9C22.5 10.8176 21.6855 12.5041 20.3547 13.5961L13.3619 20.099C12.5943 20.8129 11.4057 20.8129 10.6381 20.0991L3.65631 13.6073C2.30058 12.4754 1.5 10.8021 1.5 9Z" fill="#DB4444"/>
                            </svg>
                        </button>
                    )
                )}

                <Link to={`/product/${data?.slug}`}>
                    <div className='max-h-[30rem] overflow-hidden rounded-[0.5rem]'>
                        <img className='rounded-[0.5rem] w-full' src={data?.image?.original} />
                    </div>

                    <div className='h-[3rem] overflow-hidden my-[0.5rem] text-[#424750] text-[16px] font-[600]'>
                        <p>{data?.title}</p>
                    </div>

                    <div className='flex justify-center items-start flex-col'>
                        {price == 'unavailable' ? (
                            <div className='text-[15px] text-[#9E9E9E] font-[600] min-h-[3.5rem]'>
                                <p>ناموجود</p>
                            </div>
                        ) : !price?.has_discount ?(
                            <div className='flex justify-center items-start flex-col min-h-[3.5rem]'>
                                <div className='flex justify-center items-center mt-[-0.25rem]'>
                                    <p className='text-[#424750] text-[16px] lg:text-[18px] font-[700] ml-[0.25rem]'>{total_amount}</p>

                                    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="..." fill="#424750" />
                                    </svg>
                                </div>
                            </div>
                        ) : (
                            <div className='flex justify-center items-start flex-col min-h-[3.5rem]'>
                                <div className='flex justify-center items-center'>
                                    <p className='bg-[#DB4444] rounded-full ml-[0.5rem] px-[0.5rem] py-[0.1rem] mb-[0.3rem] flex justify-center items-center text-[12px] text-white'>{percen}</p>
                                    <p className='text-[#9E9E9E] text-[13px] font-[400] line-through'>{amount}</p>
                                </div>

                                <div className='flex justify-center items-center mt-[-0.25rem]'>
                                    <p className='text-[#424750] text-[16px] lg:text-[18px] font-[700] ml-[0.25rem]'>{total_amount}</p>

                                    <svg width="21" height="17" viewBox="0 0 21 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="..." fill="black" />
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>

                    {price == 'unavailable' ? (
                        <button disabled={loading} type='button' className='btn-hover-search w-full mt-[1rem] cursor-pointer py-[0.75rem] bg-[#F0F0F0] rounded-[0.5rem] flex justify-center items-center'>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 2.5H6V5H8.5V7H6V9.5H4V7H1.5V5H4V2.5Z" fill="#089584"/>
                            <path d="M10.5 7H19.5002V15H6.40514L6.05514 11.5H4.04517L4.50512 16.0995C4.55624 16.6107 4.9864 17 5.50015 17H20.5002C21.0524 17 21.5002 16.5523 21.5002 16V5H23.5002V3H20.5002C19.9479 3 19.5002 3.44772 19.5002 4V5H10.5V7Z" fill="#089584"/>
                            <path d="M6.50006 22C5.39549 22 4.50006 21.1046 4.50006 20C4.50006 18.8954 5.39549 18 6.50006 18C7.60463 18 8.50006 18.8954 8.50006 20C8.50006 21.1046 7.60463 22 6.50006 22Z" fill="#089584"/>
                            <path d="M17.5001 20C17.5001 21.1046 18.3955 22 19.5001 22C20.6046 22 21.5001 21.1046 21.5001 20C21.5001 18.8954 20.6046 18 19.5001 18C18.3955 18 17.5001 18.8954 17.5001 20Z" fill="#089584"/>
                            </svg>

                            <p className='text-[#089584] text-[14px] font-[600] mr-[0.5rem]'>{(!loading) ? 'افزودن به سبد خرید' : 'صبر کنید ...'}</p>
                        </button>
                    ) : data?.colors?.length == 1 ? (
                        !cart?.items?.items?.find(item => item.color.id === data?.default_color?.id) ? (
                            <button onClick={(e) => handelAddToCart(e,data?.default_color?.id)} disabled={loading} className='btn-hover-search w-full mt-[1rem] cursor-pointer py-[0.75rem] bg-[#F0F0F0] rounded-[0.5rem] flex justify-center items-center'>
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4 2.5H6V5H8.5V7H6V9.5H4V7H1.5V5H4V2.5Z" fill="#089584"/>
                                <path d="M10.5 7H19.5002V15H6.40514L6.05514 11.5H4.04517L4.50512 16.0995C4.55624 16.6107 4.9864 17 5.50015 17H20.5002C21.0524 17 21.5002 16.5523 21.5002 16V5H23.5002V3H20.5002C19.9479 3 19.5002 3.44772 19.5002 4V5H10.5V7Z" fill="#089584"/>
                                <path d="M6.50006 22C5.39549 22 4.50006 21.1046 4.50006 20C4.50006 18.8954 5.39549 18 6.50006 18C7.60463 18 8.50006 18.8954 8.50006 20C8.50006 21.1046 7.60463 22 6.50006 22Z" fill="#089584"/>
                                <path d="M17.5001 20C17.5001 21.1046 18.3955 22 19.5001 22C20.6046 22 21.5001 21.1046 21.5001 20C21.5001 18.8954 20.6046 18 19.5001 18C18.3955 18 17.5001 18.8954 17.5001 20Z" fill="#089584"/>
                                </svg>
    
                                <p className='text-[#089584] text-[14px] font-600] mr-[0.5rem]'>{(!loading) ? 'افزودن به سبد خرید' : 'صبر کنید ...'}</p>
                            </button>
                        ) : (
                            <div className='flex-col lg:flex-row flex justify-center items-center mt-[1rem]'>
                                <div className='w-full lg:w-max flex justify-center items-center border lg:ml-[0.5rem] border-[#089584] rounded-[0.5rem] px-[10px] py-[0.75rem]'>
                                    <button disabled={loading} onClick={(e) => handelAddToCart(e,data?.default_color?.id)}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.8335 3.3335H9.16683V9.16683H3.3335V10.8335H9.16683V16.6668H10.8335V10.8335H16.6668V9.16683H10.8335V3.3335Z" fill="#089584"/>
                                        </svg>
                                    </button>

                                    {loading || cart?.status == "loading" ? (
                                        <p className='text-[#089584] mx-[0.5rem] w-[10px]'><img className='w-full' src={loadingIcon} /></p>
                                    ) : (
                                        <p className='text-[#089584] mx-[0.5rem] w-[10px] h-full'>{cart?.items?.items?.find(item => item.color.id === data?.default_color?.id)?.quantity}</p>
                                    )}
    
                                    {cart?.items?.items?.find(item => item.color.id === data?.default_color?.id)?.quantity == 1 ? (
                                        <button disabled={loading} onClick={(e) => handelClearToCart(e,data?.default_color?.id)}>
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21 5.97998C17.67 5.64998 14.32 5.47998 10.98 5.47998C9 5.47998 7.02 5.57998 5.04 5.77998L3 5.97998" stroke="#089584" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M8.5 4.97L8.72 3.66C8.88 2.71 9 2 10.69 2H13.31C15 2 15.13 2.75 15.28 3.67L15.5 4.97" stroke="#089584" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M18.85 9.14001L18.2 19.21C18.09 20.78 18 22 15.21 22H8.79002C6.00002 22 5.91002 20.78 5.80002 19.21L5.15002 9.14001" stroke="#089584" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.33 16.5H13.66" stroke="#089584" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M9.5 12.5H14.5" stroke="#089584" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </button>
                                    ) : (
                                        <button disabled={loading} onClick={(e) => handelRemoveToCart(e,data?.default_color?.id)}>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.6668 9.1665V10.8332H3.3335V9.1665H16.6668Z" fill="#089584"/>
                                            </svg>
                                        </button>
                                    )}
                                </div>
    
                                <Link to={'/cart'} disabled={loading} className='btn-hover mt-[0.5rem] lg:mt-0 bg-[#089584] w-full text-center lg:mr-[0.5rem] rounded-[0.5rem] px-[1rem] py-[0.75rem] text-white'>
                                    <p>{(!loading) ? 'رفتن به سبد': 'صبر کنید ...'}</p>
                                </Link>
                            </div>
                        )
                    ) : (
                        <button disabled={loading} className='btn-hover-search w-full mt-[1rem] cursor-pointer py-[0.75rem] bg-[#F0F0F0] rounded-[0.5rem] flex justify-center items-center'>
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 2.5H6V5H8.5V7H6V9.5H4V7H1.5V5H4V2.5Z" fill="#089584"/>
                            <path d="M10.5 7H19.5002V15H6.40514L6.05514 11.5H4.04517L4.50512 16.0995C4.55624 16.6107 4.9864 17 5.50015 17H20.5002C21.0524 17 21.5002 16.5523 21.5002 16V5H23.5002V3H20.5002C19.9479 3 19.5002 3.44772 19.5002 4V5H10.5V7Z" fill="#089584"/>
                            <path d="M6.50006 22C5.39549 22 4.50006 21.1046 4.50006 20C4.50006 18.8954 5.39549 18 6.50006 18C7.60463 18 8.50006 18.8954 8.50006 20C8.50006 21.1046 7.60463 22 6.50006 22Z" fill="#089584"/>
                            <path d="M17.5001 20C17.5001 21.1046 18.3955 22 19.5001 22C20.6046 22 21.5001 21.1046 21.5001 20C21.5001 18.8954 20.6046 18 19.5001 18C18.3955 18 17.5001 18.8954 17.5001 20Z" fill="#089584"/>
                            </svg>

                            <p className='text-[#089584] text-[14px] font-[600] mr-[0.5rem]'>{(!loading) ? 'افزودن به سبد خرید' : 'صبر کنید ...'}</p>
                        </button>
                    )}
                </Link>
            </section>
        </>
    );
};

export default ProductBox;