import React from 'react';
import { Link } from 'react-router-dom';
import useJalaliDate from '../../../Logics/useJalaliDate';

const ArticlesBox = ({ data }) => {
    const replaceZWNJWithUnicode = (text) => {
        return text.replace(/\u200C/g, '\u200C');
    };

    return (
        <>
            <section className='flex justify-center items-center flex-col lg:flex-row my-[2rem]'>
                <Link to={`/articles/${data?.slug}`} className='w-full lg:w-[50%] lg:ml-[1rem] h-full'>
                    <img className='w-full rounded-[1rem]' src={data?.media?.original} />
                </Link>

                <div className='w-full lg:w-[50%] lg:mr-[1rem] mt-[1rem] h-full'>
                    <div className='mb-[1rem]'>
                        <p className='text-[22px]'>{data?.title}</p>
                    </div>

                    <div className='flex justify-start items-center mb-[1rem]'>
                        <div className='w-[3.5rem] h-[3.5rem] min-w-[3.5rem] lg:w-[4rem] lg:h-[4rem] lg:min-w-[4rem]'>
                            <img className='w-full h-full rounded-full' src={data?.author?.image?.path} />
                        </div>

                        <div className='w-full flex-row lg:flex-col flex justify-between lg:justify-center items-start mr-[12px]'>
                            <p className='text-[#3C3C3C] text-[14px]'>{data?.author?.name}</p>

                            <p className='text-[#626262] text-[12px] mt-[0.25rem]'>{ useJalaliDate(data?.published_at) }</p>
                        </div>
                    </div>

                    <div className='h-full overflow-hidden text-justify max-h-[120px]' dangerouslySetInnerHTML={{ __html: replaceZWNJWithUnicode(data?.short_body) }}></div>

                    <div className='mt-[1rem]'>
                        <Link to={`/articles/${data?.slug}`} className='flex justify-center items-center w-full lg:w-max bg-green py-[0.7rem] px-[2rem] rounded-[0.5rem] text-white'>
                            <p>مطالعه مقاله</p>

                            <svg className='mr-[1rem]' width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.4796 12.0001L14.7725 16.293L13.3583 17.7072L8.35828 12.7072C7.96776 12.3167 7.96776 11.6835 8.35828 11.293L13.3583 6.29297L14.7725 7.70718L10.4796 12.0001Z" fill="white"/>
                            </svg>
                        </Link>
                    </div>
                </div>      
            </section>
        </>
    );
};

export default ArticlesBox;