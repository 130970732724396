import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Sidbar = () => {
    const location = useLocation();
    return (
        <>
            <section className='w-full lg:w-[17rem] lg:min-w-[17rem] mb-[1rem]'>
                <ul>
                    <li className='border-b border-[#F0F0F1]'>
                        <Link to={'/dashboard'} className={`flex justify-start items-center py-[0.75rem] hover-menu-dashboard ${(location.pathname === '/dashboard') ? 'active-menu-dashboard' : ''}`}>
                            <svg className='ml-[0.5rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C14.7614 2 17 4.23858 17 7V8C17 10.7614 14.7614 13 12 13C9.23854 13 6.99996 10.7614 6.99996 8V7C6.99996 4.23858 9.23854 2 12 2ZM21.9957 20.908C21.5716 16.3177 18.0952 14 12 14C5.90477 14 2.42829 16.3177 2.0042 20.908C1.95005 21.4941 2.41135 22 2.99996 22H21C21.5886 22 22.0499 21.4941 21.9957 20.908ZM4.18834 20C4.91617 17.3229 7.41925 16 12 16C16.5807 16 19.0838 17.3229 19.8116 20H4.18834ZM9 7C9 5.34315 10.3431 4 12 4C13.6569 4 15 5.34315 15 7V8C15 9.65685 13.6569 11 12 11C10.3431 11 9 9.65685 9 8V7Z" fill="#666666"/>
                            </svg>

                            <p>حساب کاربری من</p>
                        </Link>
                        
                        <Link to={'/address'} className={`flex justify-start items-center py-[0.75rem] hover-menu-dashboard ${(location.pathname === '/address') ? 'active-menu-dashboard' : ''}`}>
                            <div className='min-w-[24px] h-[24px] w-[24px] ml-[0.5rem]'></div>

                            <p>آدرس</p>
                        </Link>
                    </li>
                    
                    <li className='border-b border-[#F0F0F1]'>
                        <Link to={'/my-orders'} className={`flex justify-start items-center py-[0.75rem] hover-menu-dashboard ${(location.pathname === '/my-orders') ? 'active-menu-dashboard' : ''}`}>
                            <svg className='ml-[0.5rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C14.6888 2 16.8818 4.12231 16.9954 6.78311L17 7H19C19.5128 7 19.9355 7.38604 19.9933 7.88338L20 8V19C20 20.5977 18.7511 21.9037 17.1763 21.9949L17 22H7C5.40232 22 4.09634 20.7511 4.00509 19.1763L4 19V8C4 7.48716 4.38604 7.06449 4.88338 7.00673L5 7H7C7 4.23858 9.23858 2 12 2ZM18 9H17V11H15V9H9V11H7V9H6V19C6 19.4734 6.32893 19.8699 6.77071 19.9736L6.88338 19.9933L7 20H17C17.5128 20 17.9355 19.614 17.9933 19.1166L18 19V9ZM9.00509 6.82373C9.09634 5.24892 10.4023 4 12 4C13.6569 4 15 5.34315 15 7H9L9.00509 6.82373Z" fill="#666666"/>
                            </svg>

                            <p>سفارش‌ها</p>
                        </Link>
                    </li>
                    
                    <li className='border-b border-[#F0F0F1]'>
                        <Link to={'/my-wishlist'} className={`flex justify-start items-center py-[0.75rem] hover-menu-dashboard ${(location.pathname === '/my-wishlist') ? 'active-menu-dashboard' : ''}`}>
                            <svg className='ml-[0.5rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3C4.18629 3 1.5 5.68629 1.5 9C1.5 10.8021 2.30058 12.4754 3.65631 13.6073L10.6381 20.0991C11.4057 20.8129 12.5943 20.8129 13.3619 20.099L20.3547 13.5961C21.6855 12.5041 22.5 10.8176 22.5 9C22.5 5.68629 19.8137 3 16.5 3C14.7393 3 13.116 3.76553 12 5.03031C10.884 3.76553 9.26067 3 7.5 3ZM4.99778 12.1237L4.95388 12.0852C4.03888 11.329 3.5 10.2096 3.5 9C3.5 6.79086 5.29086 5 7.5 5C9.04795 5 10.4358 5.8867 11.1005 7.25491C11.4652 8.00561 12.5348 8.00561 12.8995 7.25491C13.5642 5.8867 14.9521 5 16.5 5C18.7091 5 20.5 6.79086 20.5 9C20.5 10.213 19.958 11.3353 19.0386 12.0914L12 18.6345L4.99778 12.1237Z" fill="#666666"/>
                            </svg>

                            <p>فهرست علاقه‌مندی‌ها</p>
                        </Link>
                    </li>
                    
                    <li>
                        <Link to={'/logout'} className={`flex justify-start items-center py-[0.75rem] hover-menu-dashboard text-[#FF6B6B]`}>
                            <svg className='ml-[0.5rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 15H14V17C14 18.1046 13.1046 19 12 19H6C4.89543 19 4 18.1046 4 17V7C4 5.89543 4.89543 5 6 5H12C13.1046 5 14 5.89543 14 7V9H16V7C16 4.79086 14.2091 3 12 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H12C14.2091 21 16 19.2091 16 17V15ZM7 13H19.5858L17.2929 15.2929L18.7071 16.7071L22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L18.7071 7.29289L17.2929 8.70711L19.5858 11H7V13Z" fill="#FF6B6B"/>
                            </svg>

                            <p>خروج از حساب کاربری</p>
                        </Link>
                    </li>
                </ul>
            </section>
        </>
    );
};

export default Sidbar;