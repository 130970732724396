import React, { useState, useEffect, useRef } from 'react';
import bg from './../../Assets/Images/Auth/bg.svg';
import { Link, useLocation } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import useAuthRedirect from '../../Logics/useAuthRedirect';
import 'normalize.css';
import Select from 'react-select';
import countriesData from './../../Assets/Jsons/country.json';
import instruments from './../../Assets/Jsons/instruments.json';
import { Helmet } from 'react-helmet-async';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '3rem',
        borderColor: state.isFocused ? '#E6E6E6' : '#E6E6E6',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {
            borderColor: '#E6E6E6',
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
};

const Auth = () => {
    useAuthRedirect(false);
    
    const [phoneNumber, setPhoneNumber] = useState('');
    const [passwoard, setPasswoard] = useState('');
    const [passwoardConfirm, setPasswoardConfirm] = useState('');
    const [passwoardConfirmRP, setPasswoardConfirmRP] = useState('');
    const [passwoardRP, setPasswoardRP] = useState('');
    const [username, setUsername] = useState('');
    const [otp, setOtp] = useState('');
    const [countryCode, setCountryCode] = useState('+98');
    const [saz, setSaz] = useState('setar');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState('number');
    const [type, setType] = useState('');
    const [timer, setTimer] = useState(120);
    const [cookies, setCookie] = useCookies(['authToken']);
    const [options, setOptions] = useState([]);
    const [optionsSaz, setOptionsSaz] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const validateGetToken = () => {
        let formErrors = {};
        if (!phoneNumber.trim()) formErrors.phoneNumber = 'شماره تماس اجباری است';
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    useEffect(() => {
        let interval;
        if (step === 'otp') {
            interval = setInterval(() => {
                setTimer(prev => {
                    if (prev > 0) {
                        return prev - 1;
                    } else {
                        clearInterval(interval);
                        return 0;
                    }
                });
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [step]);

    useEffect(() => {
        const formattedOptions = countriesData.map((country) => ({
            value: country.dial_code,
            label: `${country.dial_code} (${country.name})`,
        }));
        setOptions(formattedOptions);
    }, []);

    useEffect(() => {
        const formattedOptions = Object.keys(instruments).map((key) => ({
            value: instruments[key],
            label: `${instruments[key]}`,
        }));
        setOptionsSaz(formattedOptions);
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? '۰' : ''}${minutes}:${seconds < 10 ? '۰' : ''}${seconds}`;
    };

    const handleGetToken = async (e) => {
        e.preventDefault();
        if (validateGetToken()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdata = new FormData();
            formdata.append("phone", phoneNumber);
            formdata.append("country_code", countryCode);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch("https://backend.neireez.com/v1/users/exists", requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status === 200) {
                    setStep('passwoard');
                    setType('login');
                    setLoading(false);
                }else{
                    fetch("https://backend.neireez.com/v1/auth/classic/get-token", requestOptions)
                    .then((response) => {
                        return response.json().then((result) => ({ status: response.status, result }));
                    })
                    .then(({ status, result }) => {
                        if (status == 201 || status == 200 || status == 403) {
                            setStep('otp');
                            setType('register');
                            setTimer(120);
                        } else {
                            let formErrors = {};
                            if (result?.errors?.phone) {
                                formErrors.phoneNumber = result?.errors?.phone;
                            }
                            setErrors(formErrors);
                        }
                        setLoading(false);
                    })
                    .catch((error) => console.error(error));
                }
            })
            .catch((error) => console.error(error));
        }
    };

    const ResendOtp = async () => {
        setLoading(true);
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");

        const formdata = new FormData();
        formdata.append("phone", phoneNumber);
        formdata.append("country_code", countryCode);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        fetch("https://backend.neireez.com/v1/auth/classic/get-token", requestOptions)
        .then((response) => {
            return response.json().then((result) => ({ status: response.status, result }));
        })
        .then(({ status, result }) => {
            if (status !== 201) {
                if (result?.message) {
                    toast.error(result?.message,{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }
            }
            if (status === 201 || status === 403) {
                setStep('otp');
                setTimer(120);
                setLoading(false);
            }
        })
        .catch((error) => console.error(error));
    }

    const handleOtpChange = (value, index, e) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        newOtp[index] = value;
        setOtp(newOtp.join(''));
        if (value && index < 3) {
            inputRefs[index + 1].current.focus();
        }
    };

    const handleOtpKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs[index - 1].current.focus();
        }
    };

    const validateOTP = () => {
        let formErrors = {};

        if (otp.length !== 4) {
            formErrors.otp = 'کد دومرحله‌ای باید شامل ۴ رقم باشد';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleVerifyOTP = async (e) => {
        e.preventDefault();
        if (validateOTP()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdata = new FormData();
            formdata.append("phone", phoneNumber);
            formdata.append("token", otp);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            
            if(type == 'register')
            {
                fetch("https://backend.neireez.com/v1/auth/classic/verify-token", requestOptions)
                .then((response) => {
                    return response.json().then((result) => ({ status: response.status, result }));
                })
                .then(({ status, result }) => {
                    if (status !== 200) {
                        let formErrors = {};
                        if (result?.message) {
                            formErrors.otp = result?.message;
                        }
                        setErrors(formErrors);
                    }
                    if (status === 200) {
                        setStep('register');
                    }
                    setLoading(false);
                })
                .catch((error) => console.error(error));
            }else if(type == 'login'){
                const myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");

                const formdata = new FormData();
                formdata.append("credential_type", 'phone');
                formdata.append("credential", phoneNumber);
                formdata.append("password", otp);
                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: formdata,
                    redirect: "follow"
                };

                fetch("https://backend.neireez.com/v1/auth/classic/login", requestOptions)
                .then((response) => {
                    return response.json().then((result) => ({ status: response.status, result }));
                })
                .then(({ status, result }) => {
                    if (status !== 200) {
                        let formErrors = {};

                        if (result?.otp) {
                            formErrors.otp = result?.otp;
                        }

                        if (result?.message) {
                            toast.error(result?.message,{
                                style: {
                                    maxWidth: '400px',
                                    borderRadius: '10px',
                                    background: '#333',
                                    color: '#fff',
                                    textAlign: 'right',
                                    zIndex: '10000000000'
                                },
                            });
                        }

                        setErrors(formErrors);
                    }
                    if (status === 200 || status === 201) {
                        const token = result.token;
                        setCookie('authToken', token, { path: '/', maxAge: 253800 });
                        const redirectPath = location.state?.from;
                        navigate(redirectPath);
                        setPhoneNumber('');
                        setOtp(Array(4).fill(''));
                    }
                    setLoading(false);
                })
                .catch((error) => console.error(error));
            }else if(type == 'resetPasswoard'){
                fetch("https://backend.neireez.com/v1/auth/classic/verify-token", requestOptions)
                .then((response) => {
                    return response.json().then((result) => ({ status: response.status, result }));
                })
                .then(({ status, result }) => {
                    if (status !== 200) {
                        let formErrors = {};
                        if (result?.message) {
                            formErrors.otp = result?.message;
                        }
                        setErrors(formErrors);
                    }
                    if (status === 200) {
                        setStep('resetPasswoard');
                    }
                    setLoading(false);
                })
                .catch((error) => console.error(error));
            }
        }
    };

    const validateRegister = () => {
        let formErrors = {};

        if (!username.trim()) formErrors.username = 'نام و نام خانوادگی اجباری است';

        if (passwoard != passwoardConfirm) {
            formErrors.passwoard = 'رمز عبور و تکرار رمز عبور یکسان نیست';
            formErrors.passwoardConfirm = 'رمز عبور و تکرار رمز عبور یکسان نیست';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (validateRegister()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdata = new FormData();
            formdata.append("phone", phoneNumber);
            formdata.append("name", username);
            formdata.append("instrument", saz);
            formdata.append("password", passwoard);
            formdata.append("password_confirmation", passwoardConfirm);
            formdata.append("token", otp);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch("https://backend.neireez.com/v1/auth/classic/register", requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status !== 200) {
                    let formErrors = {};

                    if (result?.name) {
                        formErrors.username = result?.name;
                    }
                    
                    if (result?.passwoard) {
                        formErrors.passwoard = result?.passwoard;
                    }

                    if (result?.passwoardConfirm) {
                        formErrors.passwoardConfirm = result?.passwoardConfirm;
                    }

                    if (result?.message) {
                        toast.error(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                    }

                    setErrors(formErrors);
                }
                if (status === 200) {
                    const token = result.token;
                    setCookie('authToken', token, { path: '/', maxAge: 253800 });
                    const redirectPath = location.state?.from?.pathname || '/dashboard';
                    navigate(redirectPath, { replace: true });
                    setPhoneNumber('');
                }
                setLoading(false);
            })
            .catch((error) => console.error(error));
        }
    };

    const validateLogin = () => {
        let formErrors = {};

        if (!passwoard.trim()) formErrors.passwoard = 'رمز عبور اجباری است';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        if (validateLogin()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdata = new FormData();
            formdata.append("credential_type", 'phone');
            formdata.append("credential", phoneNumber);
            formdata.append("password", passwoard);
            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch("https://backend.neireez.com/v1/auth/classic/login", requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status !== 200) {
                    let formErrors = {};

                    if (result?.otp) {
                        formErrors.otp = result?.otp;
                    }

                    if (result?.message) {
                        toast.error(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                    }

                    setErrors(formErrors);
                }
                if (status === 200) {
                    const token = result.token;
                    setCookie('authToken', token, { path: '/', maxAge: 253800 });
                    const redirectPath = localStorage.getItem('redirectPath') || '/';
                    localStorage.removeItem('redirectPath');
                    navigate(redirectPath);
                }
                setLoading(false);
            })
            .catch((error) => console.error(error));
        }
    };

    const handelSetResetPasswoard = () => {
        setType('resetPasswoard');
        ResendOtp();
    }

    const validateResetPasswoard = () => {
        let formErrors = {};
        

        if (!passwoardRP.trim()) formErrors.passwoardRP = 'رمز عبور اجباری است';

        if (!passwoardConfirmRP.trim()) formErrors.passwoardConfirmRP = 'رمز عبور اجباری است';

        if (passwoardRP != passwoardConfirmRP) {
            formErrors.passwoardRP = 'رمز عبور و تکرار رمز عبور یکسان نیست';
            formErrors.passwoardConfirmRP = 'رمز عبور و تکرار رمز عبور یکسان نیست';
        }

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleResetPasswoard = async (e) => {
        e.preventDefault();
        if (validateResetPasswoard()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");

            const formdata = new FormData();
            formdata.append("token", otp);
            formdata.append("phone", phoneNumber);
            formdata.append("password", passwoardRP);
            formdata.append("password_confirmation", passwoardConfirmRP);

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch("https://backend.neireez.com/v1/auth/classic/reset-password", requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status !== 200) {
                    let formErrors = {};

                    if (result?.otp) {
                        formErrors.otp = result?.otp;
                    }

                    if (result?.message) {
                        toast.error(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                    }

                    setErrors(formErrors);
                }
                if (status === 200) {
                    if (result?.message) {
                        toast.success(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                        setLoading(false);
                        setStep('number');
                        setOtp(Array(4).fill(''));
                        setPhoneNumber('');
                    }
                }
                setLoading(false);
            })
            .catch((error) => console.error(error));
        }
    };

    const handleCountryChange = (selectedOption) => {
        setCountryCode(selectedOption.value);
    };

    const handleSazChange = (selectedOption) => {
        setSaz(selectedOption.value);
    };
    
    return (
        <>
            <Helmet>
                <title>ورود و ثبت نام - نیریز</title>
            </Helmet>

            <Toaster />

            <main className='flex justify-between items-center flex-col-reverse lg:flex-row py-[2rem] px-[2rem] lg:px-[4rem]'>
                <section className='w-full lg:w-[40%]'>
                    <h3 className='text-[#0C0C0C] text-[28px] font-[600] mb-[1rem]'>ورود به حساب‌کاربری</h3>

                    {(step === 'number') ? (
                        <form onSubmit={handleGetToken} className='mt-[2rem]'>
                            <div className='flex flex-col w-full gap-4'>
                                <div className='w-full'>
                                    {options.length > 0 && (
                                        <Select
                                            options={options}
                                            onChange={handleCountryChange}
                                            placeholder="انتخاب کشور"
                                            styles={customStyles}
                                            defaultValue={options[0]}
                                            isSearchable={false}
                                        />
                                    )}
                                </div>

                                <input
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder='مثال: ۰۹۱۲۹۸۷۶۵۴۳۲'
                                    className={`${errors.phoneNumber ? '!border-red-500' : ''} w-full input-hover text-right hide-defult-style-input-number p-[0.5rem] h-[3rem] border border-[#E6E6E6] rounded-[0.5rem]`}
                                    id='mobile'
                                    type='tel'
                                    inputMode='numeric'
                                    pattern='[0-9]*'
                                />
                            </div>
                            {errors.phoneNumber && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.phoneNumber}</p>}
                            <div className='mt-[1.5rem]'>
                                <button className='btn-hover w-full lg:w-[50%] flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                    <p>{loading ? 'صبر کنید ...' : 'ورود'}</p>
                                </button>
                            </div>
                        </form>
                    ) : (step === 'otp') ? (
                        <form onSubmit={handleVerifyOTP}>
                            <div className='flex justify-center items-start flex-col mt-[2rem]'>
                                <label htmlFor='mobile' className='mb-[1rem] text-[#424242] text-[14px] lg:text-[18px] font-[500]'>
                                    کد تایید به شماره‌ی {phoneNumber} ارسال شد
                                </label>

                                <div className='grid grid-cols-4 gap-4 w-full lg:w-[50%]' dir='ltr'>
                                    {Array.from({ length: 4 }, (_, index) => (
                                        <input
                                            key={index}
                                            ref={inputRefs[index]}
                                            value={otp[index] || ''}
                                            onChange={(e) => handleOtpChange(e.target.value, index, e)}
                                            onKeyDown={(e) => handleOtpKeyDown(e, index)}
                                            className='p-[0.5rem] text-center text-black font-bold border-b border-[#9F9F9F]'
                                            type='text'
                                            maxLength='1'
                                            inputMode='numeric'
                                            pattern='[0-9]*'
                                        />
                                    ))}
                                </div>

                                {errors.otp && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.otp}</p>}
                            </div>

                            <div className='mt-[1rem] lg:w-[50%]'>
                                
                                {timer > 0 ? (
                                    <p className='text-[#626262] text-[14px] number-farsi'>{formatTime(timer)} مانده تا درخواست مجدد کد</p>
                                ) : (
                                    <p onClick={ResendOtp} className='cursor-pointer text-[#089584] text-[14px] number-farsi flex justify-center items-center lg:justify-start'>
                                        <span>درخواست مجدد کد </span>

                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.01184 10.0001L12.5893 13.5775L11.4107 14.756L7.24408 10.5893C6.91864 10.2639 6.91864 9.73624 7.24408 9.41081L11.4107 5.24414L12.5893 6.42265L9.01184 10.0001Z" fill="#089584"/>
                                        </svg>
                                    </p>
                                )}
                            </div>

                            <div className='mt-[1.5rem]'>
                                <button className='btn-hover w-full lg:w-[50%] flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                    <p>{loading ? 'صبر کنید ...' : 'ورود'}</p>
                                </button>
                            </div>
                        </form>
                    ): (step === 'register') ? (
                        <form onSubmit={handleRegister}>
                            <div className='flex justify-center items-start flex-col mt-[1rem]'>
                                <div className='w-full'>
                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='username' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[12px] lg:text-[14px] font-[500]'>
                                            ساز
                                        </label>

                                        <div className='w-full lg:w-[70%]'>
                                            {optionsSaz.length > 0 && (
                                                <Select
                                                    options={optionsSaz}
                                                    onChange={handleSazChange}
                                                    placeholder="انتخاب ساز"
                                                    styles={customStyles}
                                                    defaultValue={optionsSaz[0]}
                                                    isSearchable={false}
                                                />
                                            )}
                                        </div>

                                        {errors.saz && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.saz}</p>}
                                    </div>

                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='username' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[12px] lg:text-[14px] font-[500]'>
                                            نام و نام خانوادگی
                                        </label>

                                        <input
                                            onChange={(e) => setUsername(e.target.value)}
                                            className={`${errors.username ? '!border-red-500' : ''} input-hoverhide-defult-style-input-number p-[0.5rem] h-[3rem] w-full lg:w-[70%] border border-[#E6E6E6] rounded-[0.5rem]`}
                                            id='username'
                                            type='text'
                                        />

                                        {errors.username && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.username}</p>}
                                    </div>

                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='passwoard' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[14px] lg:text-[14px] font-[500]'>
                                            رمز عبور
                                        </label>

                                        <input
                                            onChange={(e) => setPasswoard(e.target.value)}
                                            className={`${errors.passwoard ? '!border-red-500' : ''} input-hover hide-defult-style-input-number p-[0.5rem] h-[3rem] w-full lg:w-[70%] border border-[#E6E6E6] rounded-[0.5rem]`}
                                            id='passwoard'
                                            type='password'
                                        />

                                        {errors.passwoard && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.passwoard}</p>}
                                    </div>

                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='passwoardConfirm' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[14px] lg:text-[14px] font-[500]'>
                                            تکرار رمز عبور
                                        </label>

                                        <input
                                            onChange={(e) => setPasswoardConfirm(e.target.value)}
                                            className={`${errors.passwoardConfirm ? '!border-red-500' : ''} input-hover hide-defult-style-input-number p-[0.5rem] h-[3rem] w-full lg:w-[70%] border border-[#E6E6E6] rounded-[0.5rem]`}
                                            id='passwoardConfirm'
                                            type='password'
                                        />

                                        {errors.passwoardConfirm && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.passwoardConfirm}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className='mt-[1.5rem]'>
                                <button className='btn-hover w-full lg:w-[50%] flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                    <p>{loading ? 'صبر کنید ...' : 'ورود'}</p>
                                </button>
                            </div>
                        </form>
                    ) : (step === 'passwoard') ? (
                        <form onSubmit={handleLogin}>
                            <div className='flex justify-center items-start flex-col mt-[1rem]'>
                                <div className='w-full'>
                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='passwoard' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[14px] lg:text-[14px] font-[500]'>
                                            رمز عبور
                                        </label>

                                        <input
                                            onChange={(e) => setPasswoard(e.target.value)}
                                            className={`${errors.passwoard ? '!border-red-500' : ''} input-hover hide-defult-style-input-number p-[0.5rem] h-[3rem] w-full lg:w-[70%] border border-[#E6E6E6] rounded-[0.5rem]`}
                                            id='passwoard'
                                            type='password'
                                        />

                                        {errors.passwoard && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.passwoard}</p>}
                                    </div>

                                    <p onClick={handelSetResetPasswoard} className='mt-[1rem] cursor-pointer text-[#089584] text-[14px] number-farsi flex justify-center items-center lg:justify-start'>
                                        <span>فراموشی رمز عبور</span>

                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.01184 10.0001L12.5893 13.5775L11.4107 14.756L7.24408 10.5893C6.91864 10.2639 6.91864 9.73624 7.24408 9.41081L11.4107 5.24414L12.5893 6.42265L9.01184 10.0001Z" fill="#089584"/>
                                        </svg>
                                    </p>

                                    <p onClick={ResendOtp} className='mt-[0.5rem] cursor-pointer text-[#089584] text-[14px] number-farsi flex justify-center items-center lg:justify-start'>
                                        <span>ورود با رمز یک‌بار مصرف</span>

                                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.01184 10.0001L12.5893 13.5775L11.4107 14.756L7.24408 10.5893C6.91864 10.2639 6.91864 9.73624 7.24408 9.41081L11.4107 5.24414L12.5893 6.42265L9.01184 10.0001Z" fill="#089584"/>
                                        </svg>
                                    </p>
                                </div>
                            </div>

                            <div className='mt-[1.5rem]'>
                                <button className='w-full lg:w-[50%] flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                    <p>{loading ? 'صبر کنید ...' : 'ورود'}</p>
                                </button>
                            </div>
                        </form>
                    ) : (step === 'resetPasswoard') ? (
                        <form onSubmit={handleResetPasswoard}>
                            <div className='flex justify-center items-start flex-col mt-[1rem]'>
                                <div className='w-full'>
                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='passwoardRP' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[14px] lg:text-[14px] font-[500]'>
                                            رمز عبور
                                        </label>

                                        <input
                                            onChange={(e) => setPasswoardRP(e.target.value)}
                                            className={`${errors.passwoardRP ? '!border-red-500' : ''} input-hover hide-defult-style-input-number p-[0.5rem] h-[3rem] w-full lg:w-[70%] border border-[#E6E6E6] rounded-[0.5rem]`}
                                            id='passwoardRP'
                                            type='password'
                                        />

                                        {errors.passwoardRP && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.passwoardRP}</p>}
                                    </div>

                                    <div className='flex justify-center items-start flex-col'>
                                        <label htmlFor='passwoardConfirmRP' className='mb-[0.5rem] mt-[1rem] text-[#424242] text-[14px] lg:text-[14px] font-[500]'>
                                            تکرار رمز عبور
                                        </label>

                                        <input
                                            onChange={(e) => setPasswoardConfirmRP(e.target.value)}
                                            className={`${errors.passwoardConfirmRP ? '!border-red-500' : ''} input-hover hide-defult-style-input-number p-[0.5rem] h-[3rem] w-full lg:w-[70%] border border-[#E6E6E6] rounded-[0.5rem]`}
                                            id='passwoardConfirmRP'
                                            type='password'
                                        />

                                        {errors.passwoardConfirmRP && <p className='text-red-500 text-sm pr-[0.5rem] mt-[0.5rem]'>{errors.passwoardConfirmRP}</p>}
                                    </div>
                                </div>
                            </div>

                            <div className='mt-[1.5rem]'>
                                <button className='btn-hover w-full lg:w-[50%] flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                    <p>{loading ? 'صبر کنید ...' : 'ورود'}</p>
                                </button>
                            </div>
                        </form>
                    ) : (
                        <></>
                    )}

                    <div className='mt-[1rem] text-[#000000] text-[15px] font-[500]'>
                        <p>
                            ورود شما به معنای پذیرش 
                            <Link to={'/policy'} className='text-[#089584]'> قوانین و مقررات سایت </Link> 
                            است
                        </p>
                    </div>
                </section>

                <section className='lg:w-[50%] lg:mr-[2rem] py-[2rem] flex justify-center items-center'>
                    <img src={bg} className='w-[85%]' />
                </section>
            </main>
        </>
    );
};

export default Auth;
