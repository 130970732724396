import React from 'react';
import { Link } from 'react-router-dom';

const TeamNeireezBox = ({ data }) => {
    return (
        <>
            <div className='border border-[#E5E5E5] bg-white rounded-[24px] w-full p-[20px]'>
                <div className='w-full'>
                    <img className='w-full rounded-img-profile-team' src={data?.media?.original} />
                </div>

                <div className='w-full mt-[0.5rem]'>
                    <p className='text-[#212121] lg:text-[23px] font-[600] text-center text-nowrap whitespace-nowrap overflow-hidden'>{ data?.name }</p>
                </div>

                <div className='w-full'>
                    <p className='text-[#626262] text-[10px] mt-[0.2rem] lg:text-[12px] font-[400] text-center text-nowrap whitespace-nowrap overflow-hidden'>{ data?.role }</p>
                </div>

                <div className='flex justify-center items-center mt-[1rem]'>
                    {data?.instagram && (
                        <a href={data?.instagram} className='item-team-neireez-social'>
                            <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M19.8333 3.5H8.16667C6.92899 3.5 5.742 3.99167 4.86683 4.86683C3.99167 5.742 3.5 6.92899 3.5 8.16667V19.8333C3.5 21.071 3.99167 22.258 4.86683 23.1332C5.742 24.0083 6.92899 24.5 8.16667 24.5H19.8333C21.071 24.5 22.258 24.0083 23.1332 23.1332C24.0083 22.258 24.5 21.071 24.5 19.8333V8.16667C24.5 6.92899 24.0083 5.742 23.1332 4.86683C22.258 3.99167 21.071 3.5 19.8333 3.5Z" stroke="#0C0C0C" strokeWidth="2" strokeLinejoin="round"/>
                            <path d="M13.9997 18.6663C15.2374 18.6663 16.4243 18.1747 17.2995 17.2995C18.1747 16.4243 18.6663 15.2374 18.6663 13.9997C18.6663 12.762 18.1747 11.575 17.2995 10.6998C16.4243 9.82467 15.2374 9.33301 13.9997 9.33301C12.762 9.33301 11.575 9.82467 10.6998 10.6998C9.82467 11.575 9.33301 12.762 9.33301 13.9997C9.33301 15.2374 9.82467 16.4243 10.6998 17.2995C11.575 18.1747 12.762 18.6663 13.9997 18.6663V18.6663Z" stroke="#0C0C0C" strokeWidth="2" strokeLinejoin="round"/>
                            <path d="M20.4167 8.75033C20.7261 8.75033 21.0228 8.62741 21.2416 8.40862C21.4604 8.18982 21.5833 7.89308 21.5833 7.58366C21.5833 7.27424 21.4604 6.97749 21.2416 6.7587C21.0228 6.53991 20.7261 6.41699 20.4167 6.41699C20.1072 6.41699 19.8105 6.53991 19.5917 6.7587C19.3729 6.97749 19.25 7.27424 19.25 7.58366C19.25 7.89308 19.3729 8.18982 19.5917 8.40862C19.8105 8.62741 20.1072 8.75033 20.4167 8.75033Z" fill="#0C0C0C"/>
                            </svg>
                        </a>
                    )}

                    {data?.linkedin && (
                        <a href={data?.linkedin} className='item-team-neireez-social'>
                            <svg width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.4167 10.5583C14.4865 9.46517 15.8795 8.75 17.5 8.75C19.2018 8.75 20.8339 9.42604 22.0373 10.6294C23.2406 11.8328 23.9167 13.4649 23.9167 15.1667V23.9167H21.5833V15.1667C21.5833 14.0837 21.1531 13.0451 20.3874 12.2793C19.6216 11.5135 18.583 11.0833 17.5 11.0833C16.417 11.0833 15.3784 11.5135 14.6126 12.2793C13.8469 13.0451 13.4167 14.0837 13.4167 15.1667V23.9167H11.0833V9.33333H13.4167V10.5583ZM5.25 7C4.78587 7 4.34075 6.81563 4.01256 6.48744C3.68437 6.15925 3.5 5.71413 3.5 5.25C3.5 4.78587 3.68437 4.34075 4.01256 4.01256C4.34075 3.68437 4.78587 3.5 5.25 3.5C5.71413 3.5 6.15925 3.68437 6.48744 4.01256C6.81563 4.34075 7 4.78587 7 5.25C7 5.71413 6.81563 6.15925 6.48744 6.48744C6.15925 6.81563 5.71413 7 5.25 7ZM4.08333 9.33333H6.41667V23.9167H4.08333V9.33333Z" fill="#0C0C0C"/>
                            </svg>
                        </a>
                    )}

                    {data?.email && (
                        <a href={data?.email} className='item-team-neireez-social'>
                            <svg width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5 8.16659C3.5 7.54775 3.74583 6.95425 4.18342 6.51667C4.621 6.07908 5.21449 5.83325 5.83333 5.83325H22.1667C22.7855 5.83325 23.379 6.07908 23.8166 6.51667C24.2542 6.95425 24.5 7.54775 24.5 8.16659V19.8333C24.5 20.4521 24.2542 21.0456 23.8166 21.4832C23.379 21.9208 22.7855 22.1666 22.1667 22.1666H5.83333C5.21449 22.1666 4.621 21.9208 4.18342 21.4832C3.74583 21.0456 3.5 20.4521 3.5 19.8333V8.16659Z" stroke="#0C0C0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M3.5 8.1665L14 15.1665L24.5 8.1665" stroke="#0C0C0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </a>
                    )}

                    {data?.site && (
                        <a href={data?.site} className='item-team-neireez-social'>
                            <svg width="21" height="21" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M25 13.5C25 19.299 20.299 24 14.5 24M25 13.5C25 7.70101 20.299 3 14.5 3M25 13.5H4M14.5 24C8.70101 24 4 19.299 4 13.5M14.5 24V3M14.5 24C17.7217 24 20.3333 19.299 20.3333 13.5M14.5 24C11.2783 24 8.66667 19.299 8.66667 13.5M4 13.5C4 7.70101 8.70101 3 14.5 3M14.5 3C11.2783 3 8.66667 7.70101 8.66667 13.5M14.5 3C17.7217 3 20.3333 7.70101 20.3333 13.5M20.3333 13.5H8.66667" stroke="#0C0C0C" stroke-width="2"/>
                            </svg>
                        </a>
                    )}
                </div>
            </div>
        </>
    );
};

export default TeamNeireezBox;