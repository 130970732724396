import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import ProductBox from '../../Components/Leyouts/Search/ProductBox';
import { Link, useLocation } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from 'react-query';
import ReactSlider from 'react-slider';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet-async';
import empty from './../../Assets/Images/Account/order-empty.svg';

const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
};


const fetchData = async (page = 1, categoryid, query, orderBy, direction, amount, token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(token)
    {
        myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let categoryQuery = '';
    if (categoryid && categoryid !== "all") {
        categoryQuery = `&category=${categoryid}`;
    }

    let searchQuery = '';
    if (query) {
        searchQuery = `&q=${query}`;
    }

    let orderByQuery = '';
    if (orderBy) {
        orderByQuery = `&order_by=${orderBy}`;
    }

    let directionQuery = '';
    if (direction) {
        directionQuery = `&direction=${direction}`;
    }

    let amountQuery = '';
    if (amount) {
        amountQuery = `&min_amount=${amount[0]}&max_amount=${amount[1]}`;
    }

    const response = await fetch(`https://backend.neireez.com/v1/products?per_page=5&page=${page}${categoryQuery}${searchQuery}${orderByQuery}${directionQuery}${amountQuery}`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchCategory = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/categories?type=Product`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchPrice = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/products`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};


const Search = () => {
    const [boxPosition, setBoxPosition] = useState(null);
    const [activeSlide, setActiveSlide] = useState(null);
    const boxRef = useRef(null);
    const [categoryid, setCategoryid] = useState('all');
    const [bottomReached, setBottomReached] = useState(false);
    const [category, setCategory] = useState();
    const [direction, setDirection] = useState('asc');
    const [orderBy, setOrderBy] = useState('amount');
    const [sort, setSort] = useState('ارزان ترین');
    const [amount, setAmount] = useState();
    const [query, setQuery] = useState();

    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;

    const queryParams = useQueryParams();
    const location = useLocation();

    useEffect(() => {
        setQuery(queryParams.get('q'));
    }, [location])
    
    const categoryQ = queryParams.get('category');

    
    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);

    const { data: lastProducts, fetchNextPage, refetch, hasNextPage, isLoading, isError, error } = useInfiniteQuery(
        ['page', categoryid, query, orderBy, direction, amount, token],
        ({ pageParam = 1 }) => fetchData(pageParam, categoryid, query, orderBy, direction, amount ,token),
        {
            getNextPageParam: (lastPage) => 
                lastPage.meta.current_page < lastPage.meta.last_page 
                    ? lastPage.meta.current_page + 1 
                    : null,
            refetchOnWindowFocus: false,
        }
    );

    const { data : categoryProducts, isLoadingCategory, errorSlider } = useQuery(['data', 'category'], () => fetchCategory(),{
        retry: false,
        refetch: false,
        refetchOnWindowFocus: false,
    });

    const { data : price} = useQuery(['data', 'price'], () => fetchPrice(),{
        retry: false,
        refetch: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (categoryQ && categoryProducts) {
            setCategoryid(categoryQ);
            setCategory(categoryProducts?.data?.find(item => item.id == categoryQ)?.title);
        }
    }, [categoryQ,categoryProducts])

    useEffect(() => {
        const handleScroll = () => {
            const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - document.getElementsByTagName('footer')?.[0]?.scrollHeight;
            setBottomReached(isBottom);
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        if (bottomReached && hasNextPage && !isLoading) {
            fetchNextPage();
        }
    }, [bottomReached, fetchNextPage, hasNextPage, isLoading,categoryid,direction,orderBy,amount]);
    
    const toggleSlide = (slideName, e) => {
        e.stopPropagation();
        setActiveSlide(activeSlide === slideName ? null : slideName);
        const buttonRect = e.target.getBoundingClientRect();
        const boxWidth = 200;
        const boxHeight = 150;
    
        let top = buttonRect.bottom + window.scrollY + 15;
        let left = buttonRect.right - boxWidth + window.scrollX;
    
        const marginFromEdge = 10;
        if (left + boxWidth > window.innerWidth) {
            left = window.innerWidth - boxWidth - marginFromEdge;
        }
    
        if (left < marginFromEdge) {
            left = marginFromEdge;
        }
    
        if (top + boxHeight > window.innerHeight + window.scrollY) {
            top = window.innerHeight + window.scrollY - boxHeight - marginFromEdge;
        }
    
        setBoxPosition({ top, left });
    };

    const handleClickOutside = (event) => {
        if (boxRef.current && !boxRef.current.contains(event.target)) {
            setActiveSlide(null);
        }
    };
    
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
    }, []);

    const setCategoryItem = (id) => {
        setCategoryid(id);
        setCategory(categoryProducts?.data?.find(item => item.id === id).title);
        setActiveSlide(null);
    }

    const clear = () => {
        setCategory('all');
        setCategoryid('all');
        setAmount(0);
        setQuery(null);
        setPriceRange([price?.min_amount || 0, price?.max_amount || 0]);
    }

    const handelSort = (orderBy,direction,title) => {
        setOrderBy(orderBy);
        setDirection(direction);
        setSort(title);
        setActiveSlide(null);
    }

    const [priceRange, setPriceRange] = useState([price?.min_amount || 0, price?.max_amount || 0]);

    useEffect(() => {
        if (price) {
            setPriceRange([price.min_amount, price.max_amount]);
            const formattedRange = priceRange.map(value => Math.round(value));
            setAmount(formattedRange);
        }
    }, [price]);

    const handleRangeChange = (newRange) => {
        const formattedRange = newRange.map(value => Math.round(value));
        if(newRange[0] && newRange[1])
        {
            setPriceRange(formattedRange);
        }
    };

    const handleSubmitPriceRange = () => {
        const formattedRange = priceRange.map(value => Math.round(value));
        setAmount(formattedRange);
        setActiveSlide(null);
    };

    const FormattedAmount = (amount) => {
        if (amount == null) return '';
        
        const number = Math.floor(parseFloat(amount));
        return number.toLocaleString('fa-IR');
    };

    return (
        <>
            <Helmet>
                <title>جستجو - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="جستجو - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            <main className='py-[2rem] lg:px-[4rem]'>
                <section className='lg:px-[2rem] lg:py-[1rem] w-full lg:border border-[#F0F0F0] rounded-[0.5rem]'>
                    <section className='flex justify-center items-start lg:items-center flex-col lg:flex-row'>
                        {query && (
                            <div className='text-nowrap px-[1rem] lg:px-0 lg:ml-[2.5rem]'>
                                <h3 className='text-[#0C0C0C] text-[24px] font-[600]'>نتایج جستجو برای “{query}”</h3>
                            </div>
                        )}

                        <div className='w-full'>
                            <Swiper
                                slidesPerView={'auto'}
                                grabCursor={true}
                                spaceBetween={14}
                                slidesOffsetAfter={16}
                                slidesOffsetBefore={16}
                                breakpoints={{
                                    1024: {
                                        slidesOffsetAfter :0,
                                        slidesOffsetBefore : 0,
                                    },
                                }}
                                className='lg:!px-[0.5rem] mt-[1rem] lg:mt-0'
                            >
                                <SwiperSlide onClick={(e) => toggleSlide('category',e)} className='btn-click cursor-pointer bg-[#FFFFFF] border border-[#D9D9D9] rounded-full px-[8px] py-[4px] !w-max !flex justify-center items-center'>
                                    <p className='text-[12px] text-[#212121] font-[600] ml-[0.3rem]'>{(categoryid == "all" ? 'دسته بندی' : category)}</p>

                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00045 9.96746C7.91156 9.96746 7.82823 9.95346 7.75045 9.92546C7.67267 9.89791 7.60045 9.8508 7.53378 9.78413L4.45045 6.7008C4.32823 6.57858 4.27 6.42569 4.27578 6.24213C4.28112 6.05902 4.34489 5.90635 4.46711 5.78413C4.58934 5.66191 4.74489 5.6008 4.93378 5.6008C5.12267 5.6008 5.27823 5.66191 5.40045 5.78413L8.00045 8.38413L10.6171 5.76746C10.7393 5.64524 10.8922 5.5868 11.0758 5.59213C11.2589 5.59791 11.4116 5.66191 11.5338 5.78413C11.656 5.90635 11.7171 6.06191 11.7171 6.2508C11.7171 6.43969 11.656 6.59524 11.5338 6.71746L8.46712 9.78413C8.40045 9.8508 8.32823 9.89791 8.25045 9.92546C8.17267 9.95346 8.08934 9.96746 8.00045 9.96746Z" fill="#212121"/>
                                    </svg>
                                </SwiperSlide>

                                <SwiperSlide onClick={(e) => toggleSlide('price',e)} className='btn-click cursor-pointer bg-[#FFFFFF] border border-[#D9D9D9] rounded-full px-[8px] py-[4px] !w-max !flex justify-center items-center'>
                                    <p className='text-[12px] text-[#212121] font-[600] ml-[0.3rem]'>قیمت</p>

                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8.00045 9.96746C7.91156 9.96746 7.82823 9.95346 7.75045 9.92546C7.67267 9.89791 7.60045 9.8508 7.53378 9.78413L4.45045 6.7008C4.32823 6.57858 4.27 6.42569 4.27578 6.24213C4.28112 6.05902 4.34489 5.90635 4.46711 5.78413C4.58934 5.66191 4.74489 5.6008 4.93378 5.6008C5.12267 5.6008 5.27823 5.66191 5.40045 5.78413L8.00045 8.38413L10.6171 5.76746C10.7393 5.64524 10.8922 5.5868 11.0758 5.59213C11.2589 5.59791 11.4116 5.66191 11.5338 5.78413C11.656 5.90635 11.7171 6.06191 11.7171 6.2508C11.7171 6.43969 11.656 6.59524 11.5338 6.71746L8.46712 9.78413C8.40045 9.8508 8.32823 9.89791 8.25045 9.92546C8.17267 9.95346 8.08934 9.96746 8.00045 9.96746Z" fill="#212121"/>
                                    </svg>
                                </SwiperSlide>

                                <SwiperSlide onClick={(e) => toggleSlide('sort',e)} className='btn-click cursor-pointer bg-[#FFFFFF] border border-[#D9D9D9] rounded-full px-[8px] py-[4px] !w-max !flex justify-center items-center'>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.875 3.12773H14.75C14.9625 3.12773 15.1405 3.05603 15.284 2.91265C15.428 2.76876 15.5 2.59065 15.5 2.37832C15.5 2.16598 15.428 1.98787 15.284 1.84399C15.1405 1.7006 14.9625 1.62891 14.75 1.62891H3.875C3.6625 1.62891 3.4845 1.7006 3.341 1.84399C3.197 1.98787 3.125 2.16598 3.125 2.37832C3.125 2.59065 3.197 2.76876 3.341 2.91265C3.4845 3.05603 3.6625 3.12773 3.875 3.12773Z" fill="#212121"/>
                                    <path d="M14.75 6.87478H7.25C7.0375 6.87478 6.85925 6.80283 6.71525 6.65895C6.57175 6.51556 6.5 6.3377 6.5 6.12537C6.5 5.91303 6.57175 5.73492 6.71525 5.59104C6.85925 5.44765 7.0375 5.37596 7.25 5.37596H14.75C14.9625 5.37596 15.1405 5.44765 15.284 5.59104C15.428 5.73492 15.5 5.91303 15.5 6.12537C15.5 6.3377 15.428 6.51556 15.284 6.65895C15.1405 6.80283 14.9625 6.87478 14.75 6.87478Z" fill="#212121"/>
                                    <path d="M10.25 10.6218H14.75C14.9625 10.6218 15.1405 10.5499 15.284 10.406C15.428 10.2626 15.5 10.0848 15.5 9.87242C15.5 9.66009 15.428 9.48223 15.284 9.33884C15.1405 9.19495 14.9625 9.12301 14.75 9.12301H10.25C10.0375 9.12301 9.85925 9.19495 9.71525 9.33884C9.57175 9.48223 9.5 9.66009 9.5 9.87242C9.5 10.0848 9.57175 10.2626 9.71525 10.406C9.85925 10.5499 10.0375 10.6218 10.25 10.6218Z" fill="#212121"/>
                                    <path d="M13.25 14.3689H14.75C14.9625 14.3689 15.1405 14.2969 15.284 14.153C15.428 14.0097 15.5 13.8318 15.5 13.6195C15.5 13.4071 15.428 13.2293 15.284 13.0859C15.1405 12.942 14.9625 12.8701 14.75 12.8701H13.25C13.0375 12.8701 12.8593 12.942 12.7152 13.0859C12.5718 13.2293 12.5 13.4071 12.5 13.6195C12.5 13.8318 12.5718 14.0097 12.7152 14.153C12.8593 14.2969 13.0375 14.3689 13.25 14.3689Z" fill="#212121"/>
                                    <path d="M3.60831 14.3221C3.69414 14.3533 3.7861 14.3689 3.8842 14.3689C3.98229 14.3689 4.07425 14.3533 4.16008 14.3221C4.24591 14.2908 4.32561 14.2378 4.39918 14.1628L7.04768 11.4649C7.18256 11.3275 7.25 11.1527 7.25 10.9404C7.25 10.728 7.18256 10.5532 7.04768 10.4158C6.90055 10.2659 6.72888 10.1909 6.5327 10.1909C6.33651 10.1909 6.17098 10.2596 6.0361 10.397L4.61989 11.8396L4.61985 5.37596C4.61985 5.16362 4.54935 4.98564 4.40834 4.842C4.26734 4.69837 4.09261 4.62655 3.88416 4.62655C3.67571 4.62655 3.50099 4.69837 3.35998 4.842C3.21897 4.98564 3.14847 5.16362 3.14847 5.37596L3.1485 11.8396L1.75068 10.4158C1.6158 10.2784 1.44414 10.2097 1.23569 10.2097C1.02725 10.2097 0.855586 10.2784 0.720708 10.4158C0.573569 10.5656 0.5 10.7405 0.5 10.9404C0.5 11.1402 0.567439 11.3088 0.702316 11.4462L3.36921 14.1628C3.44278 14.2378 3.52248 14.2908 3.60831 14.3221Z" fill="#212121"/>
                                    </svg>

                                    <p className='text-[12px] text-[#212121] font-[600] mr-[0.3rem]'>{sort}</p>
                                </SwiperSlide>

                                {(query || categoryid != 'all') && (
                                    <SwiperSlide onClick={(e) => clear()} className='btn-click cursor-pointer rounded-full py-[4px] !w-max !flex justify-center items-center'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.99967 11.1673L5.91634 15.2507C5.76356 15.4034 5.56912 15.4798 5.33301 15.4798C5.0969 15.4798 4.90245 15.4034 4.74967 15.2507C4.5969 15.0979 4.52051 14.9034 4.52051 14.6673C4.52051 14.4312 4.5969 14.2368 4.74967 14.084L8.83301 10.0007L4.74967 5.91732C4.5969 5.76454 4.52051 5.5701 4.52051 5.33398C4.52051 5.09787 4.5969 4.90343 4.74967 4.75065C4.90245 4.59787 5.0969 4.52148 5.33301 4.52148C5.56912 4.52148 5.76356 4.59787 5.91634 4.75065L9.99967 8.83398L14.083 4.75065C14.2358 4.59787 14.4302 4.52148 14.6663 4.52148C14.9025 4.52148 15.0969 4.59787 15.2497 4.75065C15.4025 4.90343 15.4788 5.09787 15.4788 5.33398C15.4788 5.5701 15.4025 5.76454 15.2497 5.91732L11.1663 10.0007L15.2497 14.084C15.4025 14.2368 15.4788 14.4312 15.4788 14.6673C15.4788 14.9034 15.4025 15.0979 15.2497 15.2507C15.0969 15.4034 14.9025 15.4798 14.6663 15.4798C14.4302 15.4798 14.2358 15.4034 14.083 15.2507L9.99967 11.1673Z" fill="#212121"/>
                                        </svg>

                                        <p className='text-[12px] text-[#212121] font-[600] mr-[0.3rem]'> حذف فیلتر</p>
                                    </SwiperSlide>
                                )}
                            </Swiper>
                        </div>
                    </section>

                    <section>
                        {activeSlide != null && (
                            <div
                                ref={boxRef}
                                style={{
                                    position: "absolute",
                                    top: boxPosition.top,
                                    left: boxPosition.left,
                                }}
                                className='fixed-box'
                            >
                                {activeSlide === 'category' ? (
                                    <ul className='w-full'>
                                        {categoryProducts?.data?.map((data,index) => (
                                            <li onClick={(e) => setCategoryItem(data?.id,data?.title)} className='w-full py-[0.5rem] px-[0.5rem] border-b cursor-pointer'>{data?.title}</li>
                                        ))}
                                    </ul>
                                ) : activeSlide === 'sort' ? (
                                    <ul className='w-full'>
                                        <li onClick={(e) => handelSort('amount','asc','ارزان ترین')} className='w-full py-[0.5rem] px-[0.5rem] border-b cursor-pointer'>ارزان ترین</li>

                                        <li onClick={(e) => handelSort('amount','desc','گران ترین')} className='w-full py-[0.5rem] px-[0.5rem] border-b cursor-pointer'>گران ترین</li>

                                        <li onClick={(e) => handelSort('id','asc','جدید ترین')} className='w-full py-[0.5rem] px-[0.5rem] border-b cursor-pointer'>جدید ترین</li>

                                        <li onClick={(e) => handelSort('id','desc','قدیمی ترین')} className='w-full py-[0.5rem] px-[0.5rem] border-b cursor-pointer'>قدیمی ترین</li>
                                    </ul>
                                ) : activeSlide === 'price' ? (
                                    <div className='p-[1rem] lg:w-[300px]'>
                                        <ReactSlider
                                            className="horizontal-slider"
                                            thumbClassName="thumb"
                                            trackClassName="track"
                                            value={priceRange}
                                            min={price.min_amount}
                                            max={price.max_amount}
                                            step={50000}
                                            onChange={handleRangeChange}
                                        />

                                        <div className='flex justify-between mt-2'>
                                            <span>{FormattedAmount(priceRange[1])} تومان</span>
                                            
                                            <span>{FormattedAmount(priceRange[0])} تومان</span>
                                        </div>

                                        <button
                                            onClick={handleSubmitPriceRange}
                                            className='w-full mt-4 py-2 bg-[#128E99] text-white rounded-lg'>
                                            تایید
                                        </button>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </section>

                    {isLoading ? (
                        <section className='mt-[1rem] grid grid-cols-2 lg:grid-cols-3'>
                            <section className='bg-white border border-[#F0F0F0] p-[1rem] lg:p-[2rem] w-full'>
                                <div className='max-h-[15rem] h-[13rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden relative'>
                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                </div>

                                <div className='h-[3rem] my-[0.5rem] text-[#424750] text-[16px] font-[600] bg-[#e0e0e0] rounded-[0.5rem] overflow-hidden relative'>
                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                </div>

                                <div className='flex justify-center items-start flex-col w-[50%] h-[2.5rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden relative'>
                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                </div>

                                <button className='w-full mt-[1rem] py-[1.5rem] flex justify-center items-center bg-[#e0e0e0] rounded-[0.5rem] overflow-hidden relative'>
                                    <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                                    <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                                </button>
                            </section>
                        </section>
                    ) : (
                        <>
                            {lastProducts?.pages?.[0]?.data?.length > 0 ? (
                                <section className='mt-[1rem] grid grid-cols-2 lg:grid-cols-3'>
                                    {lastProducts && lastProducts?.pages.map(pageData => (
                                        <React.Fragment key={pageData.pageNumber}>
                                            {pageData?.data.map((data,index) => (
                                                <ProductBox data={data} />
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </section>
                            ) : (
                                <div className='w-full py-[5rem] flex justify-center items-center flex-col'>
                                    <img src={empty} />

                                    <p>نتیجه ای یافت نشد.</p>

                                    <Link to={'/shop'} className='text-[#089584] text-[12px] lg:text-[14px] px-[0.75rem] py-[0.5rem] lg:px-[1.5rem] lg:py-[0.75rem] bg-[#dcf8f3] rounded-[0.5rem] mt-[1rem]'>بازگشت به فروشگاه</Link>
                                </div>
                            )}
                        </>
                    )}
                </section>
            </main>
        </>
    );
};

export default Search;