import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { logout } from './../../Store/Slices/userSlice';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['authToken']);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    removeCookie('authToken');

    useEffect(() => {
        dispatch(logout());
        navigate('/');
    }, [])
};

export default Logout;