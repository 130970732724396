import React, { useEffect } from 'react';
import { Outlet ,useLocation } from 'react-router-dom';
import Header from '../Components/Leyouts/Home/Header';
import Footer from '../Components/Leyouts/Home/Footer';
import { Toaster } from 'react-hot-toast';
import { useCookies } from 'react-cookie';
import { useQuery,useQueries } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from './../Store/Slices/userSlice';

const fetchSetting = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[2]=intro_message&names[3]=intro_link&names[4]=intro_title`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const HomeLeyout = () => {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (token) {
            dispatch(fetchUserProfile(token));
        }
    }, [token, dispatch]);

    const { data, error, isLoading } = useQuery(['settingNotif'], fetchSetting,{
        retry: false,
        refetch: false,
        refetchOnWindowFocus: false,
    });

    const location = useLocation();
    
    useEffect(() => {
        document.querySelector('#sidbar-main-mobile')?.classList?.remove('active-sidbar-mobile');
        document.querySelector('#close-sidbar-search-main')?.classList?.remove('active-sidbar-close-mobile');
        document.getElementById('sidbar-category-mobile')?.classList?.remove('active-sidbar-mobile');
        document.getElementById('close-sidbar-category-main')?.classList?.remove('active-sidbar-close-mobile');
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Header data={data} user={user} />
            
            <Outlet />

            <Footer />

            <Toaster />
        </>
    );
};

export default HomeLeyout; 