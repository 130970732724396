import React from 'react';
import AbousUsImg from './../../Assets/Images/AboutUs/AboutUs.svg';
import VectorRight from './../../Assets/Images/AboutUs/Vector-right.png';
import VectorLeft from './../../Assets/Images/AboutUs/Vector-left.png';
import TeamNirizBox from '../../Components/Leyouts/AboutUs/TeamNeireezBox';
import { useQuery,useQueries } from 'react-query';
import toast, { Toaster } from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import Futures from '../../Components/Shop/Futures';
import { Helmet } from 'react-helmet-async';

const fetchAboutUs = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/about-us`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchAboutUsTeam = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/team`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const AboutUs = () => {
    const { data : metaTga, error, isLoadingMetatag } = useQuery(['faq'], fetchmetaTga);

    const results = useQueries([
        { queryKey: ['aboutUs'], queryFn: fetchAboutUs },
        { queryKey: ['team'], queryFn: fetchAboutUsTeam }
    ]);

    const [data, team] = results;

    const isLoading = data.isLoading || team.isLoading;
    const isError = data.isError || team.isError;
    
    return (
        <>
            <Helmet>
                <title>درباره ما - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="درباره ما - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>

            {isLoading && (
                <Loading />
            )}

            <main>
                <section className='px-[2rem] lg:px-[4rem] pt-[2rem]'>
                    <h3 className='text-[28px] text-[#0C0C0C] font-[700]'>درباره ما</h3>
                </section>

                <section className='flex justify-between items-center flex-col-reverse lg:flex-row lg:bg-[#EEF8F6] my-[3rem] mt-0 lg:mt-[4rem]'>
                    {data?.data && (
                        <div className='px-[2rem] py-[2rem] lg:w-[50%] lg:pr-[4rem]' dangerouslySetInnerHTML={{ __html : data?.data['about-us'] }}></div>
                    )}

                    <div className='lg:w-[50%] py-[0.5rem] lg:mb-0 px-[2rem] lg:px-[5rem]'>
                        {data?.data && (
                            <img className='w-full h-full' src={data?.data['about-us-image'] } />
                        )}
                    </div>
                </section>

                <section className='relative flex justify-center items-center my-[3rem] min-h-[14rem] overflow-hidden'>
                    <div className='relative z-[1] w-[75vw] grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-4'>
                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] number-bold'>{ data?.data['annual-sales'] }</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>کاربران نیریز</p>
                        </div>

                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] number-bold'>{ data?.data['customers'] }</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>کاربران ایرانی و غیرایرانی از نوزده کشور</p>
                        </div>

                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] number-bold'>{ data?.data['monthly-sales'] }</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>ابزارهای تخصصی موسیقی</p>
                        </div>

                        <div className='flex justify-center items-center flex-col'>
                            {data?.data && (
                                <h2 className='text-[32px] lg:text-[58px] text-[#089584] number-bold'>{ data?.data['vendors'] }</h2>
                            )}

                            <p className='text-center text-[#3F3F3F] text-[14px] lg:text-[20px] font-[400]'>محصول برند ماد،  زیرمجموعه  نیریز</p>
                        </div>
                    </div>

                        <img className='absolute left-0 z-0' src={VectorLeft} />

                        <img className='absolute right-0 z-0' src={VectorRight} />
                </section>

                <section className='bg-[#EEF8F6] lg:m-[2rem] lg:rounded-[0.5rem] py-[2rem] px-[1rem] flex justify-center items-center flex-col'>
                    <div className='text-[#0C0C0C] text-[30px] font-[700] text-center'>
                        <p>تیم نیریــز</p>
                    </div>

                    <div className='lg:w-[60%] flex flex-wrap justify-center items-center mt-[1.5rem]'>
                        {team?.data?.data && Array.isArray(team?.data?.data) ? (
                            <>
                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[11]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[10]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[3]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[6]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[5]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[0]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[7]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[9]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[1]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[4]} />
                                </div>

                                <div className='w-full h-[1px] bg-[#b6b6b6] my-[2rem]'></div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[8]} />
                                </div>

                                <div className='w-[50%] lg:w-[25%] max-w-[400px] p-[0.5rem]'>
                                    <TeamNirizBox data={team?.data?.data[2]} />
                                </div>
                            </>
                        ) : (
                            <p>محتوایی برای نمایش یافت نشد</p>
                        )}
                    </div>
                </section>

                <Futures />
            </main>
        </>
    );
};
export default AboutUs;