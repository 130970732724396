import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { useCookies } from 'react-cookie';

export const fetchUserProfile = createAsyncThunk('user/fetchUserProfile', async (token) => {
    if (!token) {
        throw new Error('Token not found');
    }

    const response = await fetch('https://backend.neireez.com/v1/profile', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json',
        },
    });
    
    if (!response.ok) {
        throw new Error('Failed to fetch user profile');
    }

    const data = await response.json();
    return data;
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        profile: null,
        status: 'idle',
        error: null,
    },
    reducers: {
        logout: (state, action) => {
            state.profile = null;
            state.status = 'idle';
            state.error = null;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchUserProfile.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchUserProfile.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.profile = action.payload;
        })
        .addCase(fetchUserProfile.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});

export const { logout } = userSlice.actions;
export default userSlice.reducer;