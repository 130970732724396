import React from 'react';
import { Link } from 'react-router-dom';

const ContactUsItem = ({ data }) => {

    return (
        <>
            <a target='__blank' href={data?.link} className='btn-click transition-[0.3s] hover:scale-[1.015] w-full flex justify-center items-center flex-col bg-white rounded-[0.5rem] px-[1.5rem] py-[1rem] border border-[#0000001A]'>
                <div className='w-full flex justify-between items-center'>
                    <div className='flex justify-center items-center'>
                        <div className='w-[3rem] min-w-[3rem] h-[3rem]'>
                            <img className='w-full' src={data?.icon} />
                        </div>

                        <div className='mr-[1rem] text-[#0C0C0C] text-[20px] font-[500]'>
                            <p>{ data?.title }</p>
                        </div>
                    </div>

                    <div>
                        <button className='flex justify-center items-center bg-[#EEF8F6] rounded-[0.5rem] px-[1rem] py-[0.5rem]'>
                            <p className='text-[#089584] text-[14px] font-[600] cursor-pointer'>{ data?.btnText }</p>

                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.17884 10.0001L12.7562 13.5775L11.5777 14.756L7.41107 10.5893C7.08563 10.2639 7.08563 9.73624 7.41107 9.41081L11.5777 5.24414L12.7562 6.42265L9.17884 10.0001Z" fill="#089584"/>
                            </svg>
                        </button>
                    </div>
                </div>

                <div className='w-full text-[#4E4E4E] text-[16px] font-[400] mt-[1rem]'>
                    <p>{ data?.description }</p>
                </div>
            </a>
        </>
    );
};

export default ContactUsItem;