import React from 'react';
import Game from './../../Assets/Images/Shop/game.png';
import headphones from './../../Assets/Images/Shop/headphones.png';
import mobile from './../../Assets/Images/Shop/mobile.png';
import monitor from './../../Assets/Images/Shop/monitor.png';
import photo from './../../Assets/Images/Shop/photo.png';
import sport from './../../Assets/Images/Shop/sport.png';
import { Link } from 'react-router-dom';

const CategoryList = ({data}) => {
    console.log('>>>>>>>>>>>', data
        
    )
    return (
        <>
            <section className='hidden lg:flex mt-[2rem] px-[2rem] justify-center items-center'>
                <Link to={`/search`}  className='mx-[0.5rem]'>
                    <p className='text-[#666666] text-[16px] text-center font-[600] mt-[0.5rem]'>همه</p>
                </Link>

                {data && Array.isArray(data) ? (
                    data.map((data,index) => (
                        <Link to={`/search?category=${data?.id}`}  className='mx-[0.5rem]'>
                            <p className='text-[#666666] text-[14px] text-center font-[600] mt-[0.5rem]'>{data?.title}</p>
                        </Link>
                    ))
                ) : (
                    <></>
                )}
            </section>
        </>
    );
};

export default CategoryList;