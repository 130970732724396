import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import toast from 'react-hot-toast';
import Loading from '../../Components/Leyouts/Home/Loading';
import useAuthRedirect from '../../Logics/useAuthRedirect';
import { useCookies } from 'react-cookie';
import ProductBox from '../../Components/Dashbord/ProductBox';
import Select from 'react-select';
import { Helmet } from 'react-helmet-async';

const customStyles = {
    control: (provided, state) => ({
        ...provided,
        height: '3rem',
        width: '100%',
        backgroundColor: '#F5F5F5',
        borderColor: state.isFocused ? 'none' : 'none',
        borderRadius: '8px',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {
            borderColor: 'none',
        },
    }),

    valueContainer: (provided) => ({
        ...provided,
        height: '100%',
        padding: '0 8px',
    }),
    input: (provided) => ({
        ...provided,
        margin: 0,
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        height: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: '200px',
        overflowY: 'auto',
    }),
};


const fetchMyaddress = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/address`, requestOptions);
    if (!response.ok) {
        toast.error("مشکل در دریافت اطلاعات")
    }
    return response.json();
};

const fetchmetaTga = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/settings?names[0]=title&names[1]=logo&names[2]=keywords&names[3]=description`, requestOptions);
    if (!response.ok) {

    }
    return response.json();
};

const Address = () => {
    const [cookies] = useCookies(['authToken']);
    const [loading, setLoading] = useState(false);
    var token = cookies.authToken;
    const { data : Myaddress, error, isLoading } = useQuery(['Myaddress',token], () => fetchMyaddress(token));

    const [errors, setErrors] = useState({});
    const [code, setCode] = useState('');
    const [message, setMessage] = useState('');
    const [selectedProvince, setSelectedProvince] = useState(null);
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState(null);

    useEffect(() => {
        setSelectedProvince({
            value: Myaddress?.data?.city?.state?.id,
            label: Myaddress?.data?.city?.state?.name
        });
        
        if(Myaddress?.data?.city?.state?.id)
        {
            handleProvinceChange({
                value: Myaddress?.data?.city?.state?.id,
                label: Myaddress?.data?.city?.state?.name
            });
        }

        setSelectedCity({
            value: Myaddress?.data?.city?.id,
            label: Myaddress?.data?.city?.name
        });

        setCode(Myaddress?.data?.postal_code);
        setMessage(Myaddress?.data?.address1);
    }, [Myaddress])
    

    const validatePayment = () => {
        let formErrors = {};
        
        if (!code.trim()) formErrors.code = 'کد پستی اجباری است';
        if (!message.trim()) formErrors.message = 'متن آدرس اجباری است';
        if (!selectedCity) formErrors.selectedCity = 'شهر اجباری است';
        if (!selectedProvince) formErrors.selectedProvince = 'استان اجباری است';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handelSubmitPayment = (e) => {
        e.preventDefault();
        if (validatePayment()) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${token}`);
            

            const formdata = new FormData();
            formdata.append("state_id", selectedProvince?.value);
            formdata.append("city_id", selectedCity?.value);
            formdata.append("address1", message);
            formdata.append("postal_code", code);
            

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow"
            };

            fetch(`https://backend.neireez.com/v1/address`, requestOptions)
            .then((response) => {
                return response.json().then((result) => ({ status: response.status, result }));
            })
            .then(({ status, result }) => {
                if (status === 200 || status === 201) {
                    toast.success('آدرس با موفقیت ذخیره شد.',{
                        style: {
                            maxWidth: '400px',
                            borderRadius: '10px',
                            background: '#333',
                            color: '#fff',
                            textAlign: 'right',
                            zIndex: '10000000000'
                        },
                    });
                }else{
                    let formErrors = {};

                    if (result?.errors?.state_id) {
                        formErrors.selectedProvince = result?.errors?.state_id;
                    }

                    
                    if (result?.errors?.city_id) {
                        formErrors.selectedCity = result?.errors?.city_id;
                    }

                    
                    if (result?.errors?.address1) {
                        formErrors.message = result?.errors?.address1;
                    }

                    
                    if (result?.errors?.postal_code) {
                        formErrors.code = result?.errors?.postal_code;
                    }
                    
                    if (result?.message) {
                        toast.error(result?.message,{
                            style: {
                                maxWidth: '400px',
                                borderRadius: '10px',
                                background: '#333',
                                color: '#fff',
                                textAlign: 'right',
                                zIndex: '10000000000'
                            },
                        });
                    }

                    setErrors(formErrors);
                }
                setLoading(false);
            })
            .catch((error) => console.error(error));
        }
    }

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await fetch('https://backend.neireez.com/v1/locations/states');
                const data = await response.json();
                const provinceOptions = data?.data.map(province => ({
                    value: province.id,
                    label: province.name
                }));

                setProvinces(provinceOptions);
            } catch (error) {
                console.error('Error fetching provinces:', error);
            }
        };
        fetchProvinces();
    }, []);

    const handleProvinceChange = async (selectedOption) => {
        setSelectedProvince(selectedOption);
        setSelectedCity(null);
        try {
            const response = await fetch(`https://backend.neireez.com/v1/locations/cities/${selectedOption.value}`);
            const data = await response.json();
            
            const cityOptions = data?.data.map(city => ({
                value: city.id,
                label: city.name
            }));
            setCities(cityOptions);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
    };

    const { data : metaTga, errorMetatag, isLoadingMetatag } = useQuery(['metaTga'], fetchmetaTga);
    
    return (
        <>
            <Helmet>
                <title>آدرس - نیریز</title>
                <meta name="keywords" content={metaTga?.keywords} />
                <meta name="description" content={metaTga?.description} />
                <meta property="og:title" content="آدرس - نیریز" />
                <meta property="og:description" content={metaTga?.description} />
            </Helmet>
            
            <section className='w-full lg:border border-[#F0F0F0] rounded-[0.5rem] mt-[1rem] lg:mt-0 lg:mr-[3rem] lg:px-[2rem] lg:py-[1rem]'>
                <div className='flex justify-between items-center'>
                    <h3 className='text-[17px] lg:text-[24px] text-[#0C0C0C] font-[600] ml-[0.5rem]'>ویرایش آدرس</h3>
                </div>

                <form onSubmit={handelSubmitPayment}>
                    <div className='w-full'>
                        <div className="grid gap-6 lg:grid-cols-2 lg:gap-8 mt-[1.5rem]">
                            <div className="flex justify-center items-start flex-col">
                                <label htmlFor="province" className="mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]">
                                    <p>استان</p>

                                    <p className="mr-[0.5rem] text-[#FF5959]">*</p>

                                    {errors.selectedProvince && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.selectedProvince}</p>}
                                </label>

                                <Select
                                    id="province"
                                    options={provinces}
                                    onChange={handleProvinceChange}
                                    className='w-full'
                                    styles={customStyles}
                                    placeholder={'انتخاب استان'}
                                    value={selectedProvince}
                                    isSearchable={false}
                                />
                            </div>

                            <div className="flex justify-center items-start flex-col">
                                <label htmlFor="city" className="mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]">
                                    <p>شهر</p>

                                    <p className="mr-[0.5rem] text-[#FF5959]">*</p>

                                    {errors.selectedCity && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.selectedCity}</p>}
                                </label>

                                <Select
                                    id="city"
                                    options={cities}
                                    styles={customStyles}
                                    onChange={handleCityChange}
                                    className='w-full'
                                    placeholder={'انتخاب استان'}
                                    isDisabled={!selectedProvince}
                                    value={selectedCity}
                                    isSearchable={false}
                                />
                            </div>
                        </div>

                        <div className='flex justify-center items-start flex-col mt-[1.5rem]'>
                            <label htmlFor='message' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                <p>آدرس</p>

                                <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                {errors.message && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.message}</p>}
                            </label>

                            <textarea id='message' defaultValue={Myaddress?.data?.address1} onChange={(e) => setMessage(e.target.value)} type='text' className='input-hover w-full min-h-[8rem] p-[1rem] bg-[#F5F5F5] border border-[#F5F5F5] rounded-[0.5rem]' ></textarea>
                        </div>

                        <div className='grid gap-6 lg:grid-cols-2 lg:gap-8 mt-[1.5rem]'>
                            <div className='flex justify-center items-start flex-col'>
                                <label htmlFor='code' className='mb-[0.5rem] flex justify-center items-center text-[#424242] text-[14px] font-[500]'>
                                    <p>کد پستی</p>

                                    <p className='mr-[0.5rem] text-[#FF5959]'>*</p>

                                    {errors.code && <p className='text-red-500 text-sm pr-[0.5rem] mr-[0.25rem]'>{errors.code}</p>}
                                </label>

                                <input id='code' value={Myaddress?.data?.postal_code} onChange={(e) => setCode(e.target.value)} type='text' className='input-hover w-full h-[3rem] p-[1rem] bg-[#F5F5F5] border border-[#F5F5F5] rounded-[0.5rem]' />
                            </div>
                        </div>

                        <div className='lg:w-max'>
                            <button disabled={loading} className='btn-hover w-full flex justify-center items-center mt-[2rem] bg-green py-[0.7rem] px-[4rem] rounded-[0.5rem] text-white'>
                                <p>{loading ? 'صبر کنید ...' : 'ذخیره تغییرات'}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </section>
        </>
    );
};

export default Address;