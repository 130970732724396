import { combineReducers } from 'redux';
import cartReducer from './Slices/cartSlice';
import productReducer from './Slices/productSlice';
import userReducer from './Slices/userSlice';
import WishListReducer from './Slices/WishlistSlice';

const rootReducer = combineReducers({
    cart: cartReducer,
    product: productReducer,
    user: userReducer,
    WishList: WishListReducer,
});

export default rootReducer;