import React, { useEffect } from 'react';
import { Outlet ,useLocation } from 'react-router-dom';
import Footer from '../Components/Leyouts/Home/Footer';
import Header from '../Components/Leyouts/Shop/Header';
import Navbar from '../Components/Leyouts/Shop/Navbar';
import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserProfile } from './../Store/Slices/userSlice';
import { Toaster } from 'react-hot-toast';
import { fetchCartFromAPI } from './../Store/Slices/cartSlice';

const AuthLeyout = () => {
    const dispatch = useDispatch();
    const [cookies] = useCookies(['authToken']);
    const token = cookies.authToken;
    const user = useSelector((state) => state.user);
    const cart = useSelector((state) => state.cart);

    useEffect(() => {
        if (token) {
            dispatch(fetchUserProfile(token));
        }

        dispatch(fetchCartFromAPI(token));
    }, [token, dispatch]);

    const location = useLocation();
    useEffect(() => {
        document.querySelector('#sidbar-main-mobile')?.classList?.remove('active-sidbar-mobile');
        document.querySelector('#close-sidbar-search-main')?.classList?.remove('active-sidbar-close-mobile');
        document.getElementById('sidbar-category-mobile')?.classList?.remove('active-sidbar-mobile');
        document.getElementById('close-sidbar-category-main')?.classList?.remove('active-sidbar-close-mobile');
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <Toaster />

            <Header user={user} cart={cart} />
            
            <Outlet />

            <Footer />

            <Navbar />
        </>
    );
};

export default AuthLeyout; 