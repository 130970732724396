import React, { useEffect, useRef, useState } from 'react';
import { Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import logo from './../../../Assets/Images/Leyout/header/neireez.png';

const Header = ({user,cart}) => {
    const location = useLocation();
    const headerRef = useRef(null);
    const [searchQ, setsearchQ] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const navigate = useNavigate();

    const toggleMenuMobile = () => {
        document.getElementById('sidbar-main-mobile').classList.toggle('active-sidbar-mobile');
        document.getElementById('close-sidbar-search-main').classList.toggle('active-sidbar-close-mobile');
    }

    const handleResize = () => {
        const newHeight = headerRef.current.offsetHeight + 'px';
        document.body.style.paddingTop = newHeight;
    };

    useEffect(() => {
        const headerHeight = headerRef.current.offsetHeight + 'px';
        document.body.style.paddingTop = headerHeight;

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const hideNotification = () => {
        document.getElementById('box-alert-home').classList.add('lg:hidden');
        handleResize();
    }

    const toggleUserDashboard = () => {
        document.getElementById('box-profile-header').classList.toggle('hidden');
    }

    useEffect(() => {
        const boxe = document.getElementById('box-profile-header');
        const btn = document.getElementById('btn-profile-header');

        const handleClickOutside = (event) => {
            if (boxe && !boxe.contains(event.target) && btn && !btn.contains(event.target)) {
                boxe.classList.add('hidden');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [user?.profile?.data]);

    useEffect(() => {
        const boxe = document.getElementById('box-profile-header');
        const btn = document.getElementById('btn-profile-header');

        const handleClickOutside = (event) => {
            if (boxe && !boxe.contains(event.target) && btn && !btn.contains(event.target)) {
                boxe.classList.add('hidden');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [user?.profile?.data]);

    const handelSearchLink = (q) => {
        document.getElementById('input-search-header').value = '';
        document.getElementById('input-search-header').blur();
        document.getElementById('input-search-header-mobile').value = '';
        document.getElementById('input-search-header-mobile').blur();
        setIsFocused(false);
        setsearchQ("");
        navigate(q);
    }
    
    return (
        <>
            <header ref={headerRef} id='header-home'>
                <section className='flex justify-center items-center flex-col lg:flex-row px-[1.25rem] lg:px-[2.5rem] py-[1rem] lg:py-[1.75rem]'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex justify-center items-center'>
                            <button onClick={toggleMenuMobile} className='ml-[1rem] block lg:hidden'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21 7V5H3V7H21Z" fill="#424750"/>
                                <path d="M21 11V13H3V11H21Z" fill="#424750"/>
                                <path d="M21 17V19H3V17H21Z" fill="#424750"/>
                                </svg>
                            </button>

                            <Link to={'/'} className='hidden lg:block ml-[1.5rem] w-[9rem]'>
                                <img className='w-full' src={logo} />
                            </Link>
                            
                            <Link to={'/shop'} className='lg:hidden'>
                                <p className='text-[#089584] text-[18px] ml-[0.5rem] lg:text-[28px] font-[700]'>فروشگاه نیریـــز</p>
                            </Link>

                            <div className='hidden lg:block'>
                                <ul className='flex justify-center items-center'>
                                    <li>
                                        <Link to={'/shop'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green hover-icon-shop-header btn-click ${(location.pathname === '/shop') ? 'active-header' : ''}`}>
                                            <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M12 2.36328C14.6888 2.36328 16.8818 4.19618 16.9954 6.49415L17 6.68146H19C19.5128 6.68146 19.9355 7.01486 19.9933 7.44438L20 7.5451V17.0451C20 18.4249 18.7511 19.5528 17.1763 19.6316L17 19.636H7C5.40232 19.636 4.09634 18.5574 4.00509 17.1973L4 17.0451V7.5451C4 7.1022 4.38604 6.73716 4.88338 6.68727L5 6.68146H7C7 4.2966 9.23858 2.36328 12 2.36328ZM18 8.40874H17V10.136H15V8.40874H9V10.136H7V8.40874H6V17.0451C6 17.4539 6.32893 17.7964 6.77071 17.8859L6.88338 17.9029L7 17.9087H17C17.5128 17.9087 17.9355 17.5753 17.9933 17.1458L18 17.0451V8.40874ZM9.00509 6.52923C9.09634 5.16917 10.4023 4.09055 12 4.09055C13.6569 4.09055 15 5.25054 15 6.68146H9L9.00509 6.52923Z" fill="#424750"/>
                                            </svg>

                                            <span className='mr-[0.5rem]'>فروشگاه</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/tools'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/tools') ? 'active-header' : ''}`}>
                                            <span>ابزار‌ها</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/articles'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/articles') ? 'active-header' : ''}`}>
                                            <span>مقالات</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/about-us'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/about-us') ? 'active-header' : ''}`}>
                                            <span>درباره‌‌ ما</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link to={'/contact-us'} className={`flex justify-center items-center mx-[0.75rem] transition-[0.3s] hover:text-green btn-click ${(location.pathname === '/contact-us') ? 'active-header' : ''}`}>
                                            <span>تماس با ما</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className='relative hidden lg:flex justify-center items-center mr-[1rem] h-[2.5rem]'>
                                <input
                                    id='input-search-header'
                                    onChange={(e) => setsearchQ(e.target.value)}
                                    onFocus={() => setIsFocused(true)}
                                    onBlur={() => setIsFocused(false)}
                                    autoComplete="off"
                                    className='h-full text-[14px] bg-[#F7F7F7] border border-[#E0E0E0] w-full xl:w-[24rem] rounded-[0.5rem] pr-[3.5rem] pl-[0.5rem] py-[0.5rem]'
                                    type='text'
                                    placeholder='دنبال چه می گردید؟ '
                                />

                                <svg className='absolute right-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.205 17.4311 13.7771 16.4727 15.0368L20.842 19.2829L19.4481 20.7171L15.0613 16.454C13.7975 17.4237 12.216 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="#424750"/>
                                </svg>

                                {(isFocused && searchQ) && (
                                    <div className='box-more-search-header' onMouseDown={(e) => e.preventDefault()}>
                                        <button onClick={(e) => handelSearchLink(`/search?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem] border-b border-[#EEEEEE]'>
                                            نمایش “{searchQ}” در فروشگاه
                                        </button>

                                        <button onClick={(e) => handelSearchLink(`/articles?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                            نمایش “{searchQ}” در مقالات
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='flex justify-center items-center'>
                            <Link to={'/cart'} className='mx-[0.75rem] relative icon-hover btn-click'>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M20 4H22V2H19C18.4477 2 18 2.44772 18 3V4H3C2.40838 4 1.94609 4.51082 2.00496 5.0995L3.00496 15.0995C3.05608 15.6107 3.48625 16 4 16H19C19.5523 16 20 15.5523 20 15V4ZM18 21C16.8954 21 16 20.1046 16 19C16 17.8954 16.8954 17 18 17C19.1046 17 20 17.8954 20 19C20 20.1046 19.1046 21 18 21ZM5 21C3.89543 21 3 20.1046 3 19C3 17.8954 3.89543 17 5 17C6.10457 17 7 17.8954 7 19C7 20.1046 6.10457 21 5 21ZM18 14V6H4.10499L4.90499 14H18Z" fill="#666666"/>
                                </svg>

                                {cart?.items?.items_count > 0 && (
                                    <div className='bg-[#089584] rounded-[0.3rem] h-[18px] border-[2px] border-white w-[20px] flex justify-center items-center absolute right-[-0.5rem] bottom-[-0.3rem]'>
                                        <span className='text-[#FFFFFF] text-[11px] number-farsi mt-[0.1rem]'>{cart?.items?.items_count}</span>
                                    </div>
                                )}
                            </Link>

                            {user?.profile?.data && (
                                <Link to={'/my-wishlist'} className='ml-[0.5rem] relative icon-hover btn-click'>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 3C4.18629 3 1.5 5.68629 1.5 9C1.5 10.8021 2.30058 12.4754 3.65631 13.6073L10.6381 20.0991C11.4057 20.8129 12.5943 20.8129 13.3619 20.099L20.3547 13.5961C21.6855 12.5041 22.5 10.8176 22.5 9C22.5 5.68629 19.8137 3 16.5 3C14.7393 3 13.116 3.76553 12 5.03031C10.884 3.76553 9.26067 3 7.5 3ZM4.99778 12.1237L4.95388 12.0852C4.03888 11.329 3.5 10.2096 3.5 9C3.5 6.79086 5.29086 5 7.5 5C9.04795 5 10.4358 5.8867 11.1005 7.25491C11.4652 8.00561 12.5348 8.00561 12.8995 7.25491C13.5642 5.8867 14.9521 5 16.5 5C18.7091 5 20.5 6.79086 20.5 9C20.5 10.213 19.958 11.3353 19.0386 12.0914L12 18.6345L4.99778 12.1237Z" fill="#666666"/>
                                    </svg>
                                </Link>
                            )}

                            {user?.profile?.data ? (
                                <div className='relative'>
                                    <button onClick={toggleUserDashboard} id='btn-profile-header' className='flex justify-center items-center'>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 2C14.7615 2 17.0001 4.23858 17.0001 7V8C17.0001 10.7614 14.7615 13 12.0001 13C9.23866 13 7.00008 10.7614 7.00008 8V7C7.00008 4.23858 9.23866 2 12.0001 2ZM21.9958 20.908C21.5718 16.3177 18.0953 14 12.0001 14C5.90489 14 2.42841 16.3177 2.00432 20.908C1.95017 21.4941 2.41147 22 3.00008 22H21.0001C21.5887 22 22.05 21.4941 21.9958 20.908ZM4.18846 20C4.91629 17.3229 7.41937 16 12.0001 16C16.5808 16 19.0839 17.3229 19.8117 20H4.18846ZM9.00012 7C9.00012 5.34315 10.3433 4 12.0001 4C13.657 4 15.0001 5.34315 15.0001 7V8C15.0001 9.65685 13.657 11 12.0001 11C10.3433 11 9.00012 9.65685 9.00012 8V7Z" fill="#666666"/>
                                        </svg>

                                        <svg className='mr-[0.3rem]' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z" fill="#666666"/>
                                        </svg>
                                    </button>

                                    <div id='box-profile-header' className='hidden'>
                                        <Link to={'/dashboard'} className='flex justify-start items-center'>   
                                            <svg className='ml-[1rem]' width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0001 6C14.7615 6 17.0001 8.23858 17.0001 11V12C17.0001 14.7614 14.7615 17 12.0001 17C9.2387 17 7.00012 14.7614 7.00012 12V11C7.00012 8.23858 9.2387 6 12.0001 6ZM21.9959 24.908C21.5718 20.3177 18.0953 18 12.0001 18C5.90493 18 2.42845 20.3177 2.00436 24.908C1.95021 25.4941 2.41151 26 3.00012 26H21.0001C21.5887 26 22.05 25.4941 21.9959 24.908ZM4.1885 24C4.91633 21.3229 7.41941 20 12.0001 20C16.5808 20 19.0839 21.3229 19.8117 24H4.1885ZM9.00016 11C9.00016 9.34315 10.3433 8 12.0002 8C13.657 8 15.0002 9.34315 15.0002 11V12C15.0002 13.6569 13.657 15 12.0002 15C10.3433 15 9.00016 13.6569 9.00016 12V11Z" fill="#666666"/>
                                            </svg>

                                            <span>حساب کاربری من</span>
                                        </Link>

                                        <Link to={'/my-orders'} className='flex justify-start items-center'>   
                                            <svg className='ml-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C14.6888 2 16.8818 4.12231 16.9954 6.78311L17 7H19C19.5128 7 19.9355 7.38604 19.9933 7.88338L20 8V19C20 20.5977 18.7511 21.9037 17.1763 21.9949L17 22H7C5.40232 22 4.09634 20.7511 4.00509 19.1763L4 19V8C4 7.48716 4.38604 7.06449 4.88338 7.00673L5 7H7C7 4.23858 9.23858 2 12 2ZM18 9H17V11H15V9H9V11H7V9H6V19C6 19.4734 6.32893 19.8699 6.77071 19.9736L6.88338 19.9933L7 20H17C17.5128 20 17.9355 19.614 17.9933 19.1166L18 19V9ZM9.00509 6.82373C9.09634 5.24892 10.4023 4 12 4C13.6569 4 15 5.34315 15 7H9L9.00509 6.82373Z" fill="#666666"/>
                                            </svg>

                                            <span>سفارش‌های من</span>
                                        </Link>

                                        <Link to={'/logout'} className='flex justify-start items-center'>   
                                            <svg className='ml-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 15H14V17C14 18.1046 13.1046 19 12 19H6C4.89543 19 4 18.1046 4 17V7C4 5.89543 4.89543 5 6 5H12C13.1046 5 14 5.89543 14 7V9H16V7C16 4.79086 14.2091 3 12 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H12C14.2091 21 16 19.2091 16 17V15ZM7 13H19.5858L17.2929 15.2929L18.7071 16.7071L22.7071 12.7071C23.0976 12.3166 23.0976 11.6834 22.7071 11.2929L18.7071 7.29289L17.2929 8.70711L19.5858 11H7V13Z" fill="#666666"/>
                                            </svg>

                                            <span>خروج از حساب کاربری</span>
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <Link to={'/auth'} className='btn-hover hidden lg:flex justify-center items-center bg-green py-[0.7rem] px-[1rem] rounded-[0.5rem] text-white'>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10 17.5V15.8333H4.16667V4.16667H10V2.5H4.16667C3.70833 2.5 3.31583 2.66333 2.98917 2.99C2.6625 3.31667 2.49944 3.70889 2.5 4.16667V15.8333C2.5 16.2917 2.66305 16.6842 2.98917 17.0108C3.31528 17.3375 3.70778 17.5006 4.16667 17.5H10ZM11.6667 14.1667L12.8125 12.9583L10.6875 10.8333H17.5V9.16667H10.6875L12.8125 7.04167L11.6667 5.83333L7.5 10L11.6667 14.1667Z" fill="white"/>
                                        </svg>

                                        <span className='mr-[0.3rem]'>ورود | ثبت‌نام</span>
                                    </Link>

                                    <Link to={'/auth'} className='btn-click flex lg:hidden justify-center items-center text-[#424750] text-[14px]'>
                                        <span className='ml-[0.3rem]'>ورود | ثبت‌نام</span>

                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M16 15H14V17C14 18.1046 13.1046 19 12 19H6C4.89543 19 4 18.1046 4 17V7C4 5.89543 4.89543 5 6 5H12C13.1046 5 14 5.89543 14 7V9H16V7C16 4.79086 14.2091 3 12 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H12C14.2091 21 16 19.2091 16 17V15ZM22 11H9.41421L11.7071 8.70711L10.2929 7.29289L6.29289 11.2929C5.90237 11.6834 5.90237 12.3166 6.29289 12.7071L10.2929 16.7071L11.7071 15.2929L9.41421 13H22V11Z" fill="#424750"/>
                                        </svg>
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>

                    <div className='relative flex lg:hidden justify-center items-center mt-[1rem] w-full h-[2.5rem]'>
                        <input
                            id='input-search-header-mobile'
                            onChange={(e) => setsearchQ(e.target.value)}
                            onFocus={() => setIsFocused(true)}
                            onBlur={() => setIsFocused(false)}
                            autoComplete="off"
                            className='h-full text-[14px] bg-[#F7F7F7] border border-[#E0E0E0] w-full xl:w-[24rem] rounded-[0.5rem] pr-[3.5rem] pl-[0.5rem] py-[0.5rem]'
                            type='text'
                            placeholder='دنبال چه می گردید؟ '
                        />

                        <svg className='absolute right-[1rem]' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5C18 12.205 17.4311 13.7771 16.4727 15.0368L20.842 19.2829L19.4481 20.7171L15.0613 16.454C13.7975 17.4237 12.216 18 10.5 18ZM16 10.5C16 13.5376 13.5376 16 10.5 16C7.46243 16 5 13.5376 5 10.5C5 7.46243 7.46243 5 10.5 5C13.5376 5 16 7.46243 16 10.5Z" fill="#424750"/>
                        </svg>

                        {(isFocused && searchQ) && (
                            <div className='box-more-search-header' onMouseDown={(e) => e.preventDefault()}>
                                <button onClick={(e) => handelSearchLink(`/search?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem] border-b border-[#EEEEEE]'>
                                    نمایش “{searchQ}” در فروشگاه
                                </button>

                                <button onClick={(e) => handelSearchLink(`/articles?q=${searchQ}`)} className='w-full text-right flex justify-start items-center px-[0.5rem] py-[0.75rem]'>
                                    نمایش “{searchQ}” در مقالات
                                </button>
                            </div>
                        )}
                    </div>
                </section>
            </header>

            <aside id='sidbar-main-mobile'>
                <section className='w-full flex flex-col p-[1rem]'>
                    <ul>
                        <li className='pb-[1.5rem] pt-[1rem] px-[0.5rem] w-full flex justify-between items-center'>
                            <Link to={'/shop'} className='flex'>
                                <p className='text-[#089584] text-[18px] ml-[0.5rem] lg:text-[28px] font-[700]'>فروشگاه نیریـــز</p>
                            </Link>

                            <button onClick={toggleMenuMobile}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.3642 7.05044L16.9499 5.63623L12.0002 10.586L7.05044 5.63623L5.63623 7.05044L10.586 12.0002L5.63623 16.9499L7.05044 18.3642L12.0002 13.4144L16.9499 18.3642L18.3642 16.9499L13.4144 12.0002L18.3642 7.05044Z" fill="#424750"/>
                                </svg>
                            </button>
                        </li>
                        
                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/'} className='flex justify-between items-center btn-click'>
                                <div className='!w-[6.5rem] flex'>
                                    <img className='w-full' src={logo} />
                                </div>

                                <div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.4171 12.0001L14.71 16.293L13.2958 17.7072L8.29582 12.7072C7.9053 12.3167 7.9053 11.6835 8.29582 11.293L13.2958 6.29297L14.71 7.70718L10.4171 12.0001Z" fill="#424750"/>
                                    </svg>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/tools'} className={`flex justify-between items-center btn-click ${(location.pathname === '/tools') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>ابزارها</p>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/articles'} className={`flex justify-between items-center btn-click ${(location.pathname === '/articles') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>مقالات</p>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/about-us'} className={`flex justify-between items-center btn-click ${(location.pathname === '/about-us') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>درباره ما</p>
                                </div>
                            </Link>
                        </li>

                        <li className='w-full border-t border-[#0000000F] py-[1rem] px-[0.5rem]'>
                            <Link to={'/contact-us'} className={`flex justify-between items-center btn-click ${(location.pathname === '/contact-us') ? 'active-header' : ''}`}>
                                <div className='flex justify-center items-center'>
                                    <p>تماس با ما</p>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </section>
            </aside>

            <div id='close-sidbar-search-main' onClick={toggleMenuMobile}></div>
        </>
    );
};

export default Header;