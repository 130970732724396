import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import ProductBox from '../Leyouts/Products/ProductBox';

const BestSellers = ({data}) => {
    return (
        <>
            <section className='my-[2rem]'>
                <div className='px-[2rem] mb-[1rem]'>
                    <h4 className='text-[28px] font-[700] text-black'>پرفروش‌های این ماه</h4>
                </div>

                <div>
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Navigation]} 
                        className="slider-shop"
                        navigation={true}
                        slidesPerView={'auto'}
                        grabCursor={true}
                        spaceBetween={16}
                        slidesOffsetAfter={16}
                        slidesOffsetBefore={16}
                        breakpoints={{
                            1024: {
                            slidesOffsetAfter :32,
                            slidesOffsetBefore : 32,
                            },
                        }}
                        speed={500}
                    >
                        {data?.items && Array.isArray(data?.items) ? (
                            data?.items.map((data,index) => (
                                <SwiperSlide className="!w-[12rem] border border-[#E1E1E180] rounded-[0.5rem]">
                                    <ProductBox data={data} />
                                </SwiperSlide>
                            ))
                        ) : (
                            <></>
                        )}
                        
                    </Swiper>
                </div>
            </section>
        </>
    );
};

export default BestSellers;