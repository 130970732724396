import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedColor: null,
    productDetails: null,
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        selectColor: (state, action) => {
            state.selectedColor = action.payload;
        },
        setProductDetails: (state, action) => {
            state.productDetails = action.payload;
        },
    },
});

export const { selectColor, setProductDetails } = productSlice.actions;

export default productSlice.reducer;