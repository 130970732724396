import React from 'react';
import { Link } from 'react-router-dom';

const Address = ({data}) => {
    return (
        <>
            <section className='my-[2rem] mx-[2rem] lg:mx-[4rem]'>
                <Link to={'/shop'} className='text-[#424750] text-[16px] font-[500]'>فروشگاه</Link>

                <span className='text-[#C0C2C5] text-[16px] font-[700] mx-[0.75rem]'>/</span>

                <Link className='text-[#424750] text-[16px] font-[500]' to={`/search?category=${data?.data?.categories[0]?.id}`}>{data?.data?.categories[0]?.title}</Link>

                <span className='text-[#C0C2C5] text-[16px] font-[700] mx-[0.75rem]'>/</span>

                <Link className='text-[#424750] text-[16px] font-[500]'>{data?.data?.title}</Link>
            </section>
        </>
    );
};

export default Address;