import React from 'react';
import { useQuery } from 'react-query';
import ProductBox from '../Leyouts/Search/ProductBox';
import { useCookies } from 'react-cookie';

const fetchProductsShop = async (token) => {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    if(token)
    {
        myHeaders.append("Authorization", `Bearer ${token}`);
    }

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    const response = await fetch(`https://backend.neireez.com/v1/products?per_page=9`, requestOptions);
    if (!response.ok) {
        throw new Error('Failed to fetch data');
    }
    return response.json();
};

const LastProduct = () => {
    const [cookies] = useCookies(['authToken']);
    var token = cookies.authToken;

    const { data : productsShop, isLoading, error } = useQuery(['data', 'productsShop'], () => fetchProductsShop(token),{
        retry: false,
        refetch: false,
        refetchOnWindowFocus: false,
    });

    return (
        <>
            <section className='my-[3rem] lg:mx-[2rem] lg:rounded-[0.5rem] grid grid-cols-2 lg:grid-cols-3 border border-[#F0F0F0]'>
                {isLoading ? (
                    <section className='bg-white border border-[#F0F0F0] p-[1rem] lg:p-[2rem] w-full'>
                        <div className='max-h-[15rem] h-[13rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden relative'>
                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                        </div>

                        <div className='h-[3rem] my-[0.5rem] text-[#424750] text-[16px] font-[600] bg-[#e0e0e0] rounded-[0.5rem] overflow-hidden relative'>
                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                        </div>

                        <div className='flex justify-center items-start flex-col w-[50%] h-[2.5rem] rounded-[0.5rem] bg-[#e0e0e0] overflow-hidden relative'>
                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                        </div>

                        <button className='w-full mt-[1rem] py-[1.5rem] flex justify-center items-center bg-[#e0e0e0] rounded-[0.5rem] overflow-hidden relative'>
                            <div className='absolute top-0 left-0 h-full w-full animate-slide'></div>
                            <div className='absolute top-0 left-0 h-full w-full bg-gradient-to-r from-transparent to-[#b0b0b0] opacity-50 animate-shimmer'></div>
                        </button>
                    </section>
                ) : (
                    <>
                        {productsShop?.data.map((data,index) => (
                            <ProductBox data={data} />
                        ))}
                    </>
                )}
            </section>
        </>
    );
};

export default LastProduct;