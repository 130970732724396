import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchWishListFromAPI = createAsyncThunk(
    'WishList/fetchWishListFromAPI',
    async (token, { rejectWithValue }) => {
        try {
            const headers = {
                'Content-Type': 'application/json',
            };
            
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }

            const response = await fetch('https://backend.neireez.com/v1/wishlist', {
                method: 'GET',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch wishlist from wishlist');
            }

            const data = await response.json();
            return data?.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const cartSlice = createSlice({
    name: 'WishList',
    initialState: {
        data: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWishListFromAPI.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWishListFromAPI.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.data = action.payload || [];
            })
            .addCase(fetchWishListFromAPI.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export default cartSlice.reducer;