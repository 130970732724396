import React from 'react';
import logo from './../../../Assets/Images/Leyout/header/neireez.png';

const Loading = () => {
    return (
        <>
            <section className='bg-white fixed z-[10000000] top-0 right-0 w-[100vw] h-[100vh] flex justify-center items-center'>
                <div className='w-[250px]'>
                    <img className='w-full' src={logo} />
                </div>
            </section>
        </>
    );
};

export default Loading;