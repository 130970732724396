import React from 'react';

const DetailsSecend = ({data}) => {
    return (
        <>
            <section className='flex justify-between items-center flex-col-reverse lg:flex-row lg:bg-[#F4F3F2] my-[3rem] mt-[6rem]'>
                <div className='px-[2rem] lg:w-[50%] lg:pr-[4rem]'>
                    <h5 className='text-[#0C0C0C] text-[18px] lg:text-[28px] number-bold mb-[0.5rem] lg:mb-[2rem]'>{data?.title}</h5>

                    <div className='text-justify'>{data?.description}</div>
                </div>

                <div className='lg:w-[50%] mb-[1rem] lg:mb-0 px-[2rem] lg:px-[5rem]'>
                    <img className='w-full mt-[-2rem] rounded-[1rem]' src={data?.items} />
                </div>
            </section>
        </>
    );
};

export default DetailsSecend;