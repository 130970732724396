import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useAuthRedirect = () => {
    const navigate = useNavigate();
    const { profile, status } = useSelector((state) => state.user);

    useEffect(() => {
        if (status === 'loading') {
            return;
        }

        if (profile) {
            const redirectPath = localStorage.getItem('redirectPath') || '/dashboard';
            localStorage.removeItem('redirectPath');

            navigate(redirectPath);
        }
    }, [profile, status, navigate]);
};

export default useAuthRedirect;
