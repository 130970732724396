import React from 'react';
import Form from './Form';

const Info = ({data}) => {
    return (
        <>
            <section className='w-full lg:w-[60%] lg:mr-[2rem]'>
                <div>
                    <h3 className='text-[26px] text-[#0C0C0C] font-[700]'>{data?.data?.title}</h3>
                </div>

                <div className='text-[#424750] text-[16px] font-[500] mt-[1rem] lg:mt-[2rem]' dangerouslySetInnerHTML={{ __html : data?.data?.description}}></div>

                <Form data={data?.data} />
            </section>
        </>
    );
};

export default Info;